import { useState } from 'react';
import { Box, Flex, Popover, Text } from '@mantine/core';
import { useSegmentStatus } from '../../store/SegmentsState';
import { useEditorColors } from '../../theme/useEditorColors';
import { Tip } from '../Generic/Tip';
import { getSegmentStatus } from './statusCodes';
import { useSegmentEditorContext } from '../../context/SegmentEditorProvider';

type StatusProps = {
  segment: Segment;
  onSetToLive?: (setToLive: boolean) => void;
};

export const Status = ({ segment, onSetToLive }: StatusProps) => {
  const colors = useEditorColors().status;
  const { settings } = useSegmentEditorContext();
  const sHash = segment.segment_hash;
  const { isSelected, isSetToLive } = useSegmentStatus(sHash);

  const currentLabel = getSegmentStatus(segment.status_code || '');
  const label = isSetToLive ? 'Live' : currentLabel;
  const setToLabel = isSetToLive ? currentLabel : 'Live';
  // Disable if segment is Live or view only
  const disableSetToLive = currentLabel === 'Live' || !!settings.viewOnly;
  const [showSetToLive, setShowSetToLive] = useState(false);

  function onClick() {
    setShowSetToLive(false);
    if (onSetToLive) {
      setTimeout(() => {
        // Delay to allow the popover to close
        onSetToLive(!isSetToLive);
      }, 100);
    }
  }

  return (
    <Popover
      disabled={disableSetToLive}
      withArrow
      opened={showSetToLive}
      offset={-8}
      arrowSize={10}
      shadow="md"
    >
      <Popover.Target>
        <Box>
          <Tip label={label} disabled={showSetToLive}>
            <Flex
              onClick={() => setShowSetToLive(!disableSetToLive)}
              mt={!isSelected ? 5 : -30}
              w={20}
              h={20}
              justify="center"
              align="center"
              bg={isSetToLive ? colors.unsaved : colors.background}
              style={{
                borderRadius: 4,
                border: `1px solid ${colors.border}`,
              }}
            >
              <Box
                w={8}
                h={8}
                bg={colors[label]?.color}
                style={{
                  borderRadius: '50%',
                  border: `1px solid ${colors[label]?.border}`,
                }}
              ></Box>
            </Flex>
          </Tip>
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <Box
          bg={'#FFFFFF'}
          c={colors.text}
          p={5}
          style={{
            borderRadius: 4,
            border: `1px solid ${colors.border}`,
          }}
        >
          <Flex align={'center'} gap={8} px={6}>
            <Box
              w={10}
              h={10}
              bg={colors[setToLabel].color}
              style={{
                borderRadius: '50%',
                border: `1px solid ${colors[setToLabel].border}`,
              }}
            ></Box>
            <Text
              onClick={onClick}
              fw={400}
              lh={1.5}
              style={{ cursor: 'pointer' }}
            >
              {isSetToLive ? `Set back to "${currentLabel}"` : 'Set to "Live"'}
            </Text>
          </Flex>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};
