import { useContext } from 'react';
import { Box, Divider } from '@mantine/core';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';
import {
  useSegmentsStateActions,
  useIsSelected,
} from '../../store/SegmentsState';

import { getSourceGlossary } from '../../functions/segmentsFunctions';

import { JliffEditor } from '../Jliff/JliffEditor';
import { Source } from '../Jliff/Source';
import { useEditorColors } from '../../theme/useEditorColors';
import { StoreApi } from 'zustand';
import { EditHistoryStore } from '../../store/EditHistoryStore';
import { UpdateUnsavedList } from '../../SegmentEditor';

type SegmentProps = {
  segment: Segment;
  jliffStore: StoreApi<EditHistoryStore>;
  filterText: string;
  onSelect: (segmentHash: string) => void;
  deleteSegment: (segmentHash: string) => Promise<void>;
  updateUnsavedList: UpdateUnsavedList;
  previewAllSegments?: (segments: Segment[]) => void;
  index: number;
};

const Segment = ({
  segment,
  jliffStore,
  filterText,
  onSelect,
  updateUnsavedList,
  deleteSegment,
  previewAllSegments,
  index,
}: SegmentProps) => {
  const { setSelected } = useSegmentsStateActions();

  const isSelected = useIsSelected(segment.segment_hash);
  const glossaryItems = useContext(SegmentEditorContext).glossary;
  const segmentGlossary = getSourceGlossary(segment, glossaryItems);

  const selectSegment = (sHash: string) => {
    if (isSelected) return false;
    setSelected(sHash);
    if (typeof onSelect === 'function') onSelect(sHash);
  };

  const colors = useEditorColors().jliffEditor;
  const borderColor = colors.border.none || '#FFFFFF';

  const isFirstSegment = index === 0;

  return (
    <Box mt={isFirstSegment ? 20 : 0}>
      {isSelected && isFirstSegment ? (
        <></>
      ) : (
        <Divider
          w="100%"
          orientation="horizontal"
          color={borderColor}
          size="sm"
        />
      )}
      <Box
        display="grid"
        m={isSelected ? 20 : 0}
        bg={colors.background}
        style={{
          gridTemplateColumns: '1fr 1fr',
          border: isSelected ? `2px solid ${colors.border.selected}` : 'none',
          borderRadius: isSelected ? 10 : 0,
        }}
        onClick={() => {
          selectSegment(segment.segment_hash);
        }}
      >
        <Source
          segment={segment}
          filterText={filterText}
          glossary={segmentGlossary}
        />
        <JliffEditor
          store={jliffStore}
          updateUnsavedList={updateUnsavedList}
          deleteSegment={deleteSegment}
          previewAllSegments={previewAllSegments}
          segment={segment}
          glossary={segmentGlossary}
          highlightText={filterText}
        />
      </Box>
    </Box>
  );
};
export default Segment;
