import { Flex, Text } from '@mantine/core';
import { useEditorColors } from '../../theme/useEditorColors';

const glossaryRules = {
  never_translate: 'Never Translate',
  translate_as: 'Translate',
  never_translate_as: "Don't Translate",
};
export const GlossaryInfo = ({ glossary }: { glossary: GlossaryItem[] }) => {
  const colors = useEditorColors().tooltip.light;

  return (
    <Flex
      direction={'column'}
      gap={4}
      bg={colors.background}
    >
      {glossary.map((g) => (
        <Text
          key={g.source_text_hash}
          c={colors.text}
          lh={1.2}
          fz={14}
        >
          {`${glossaryRules[g.rule]} “${g.source_text}” ${
            g.target_text.length
              ? ` as ${g.target_text.map((t) => `“${t}”`).join(' or ')}`
              : ''
          }`}
        </Text>
      ))}
    </Flex>
  );
};
