import React from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
} from '@mantine/core';
import { CloseCircle } from '../../icons/ActionIcons';

type ConfirmModalProps = {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  children?: React.ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  children,
  confirmLabel = 'Apply',
  cancelLabel = 'Cancel',
}) => {
  return (
    <Modal
      centered
      opened={open}
      onClose={onClose}
      size={540}
      withCloseButton={false}
      styles={{ body: { padding: 8 } }}
    >
      <Flex justify={'flex-end'}>
        <Box c="#00328D" style={{ cursor: 'pointer' }} onClick={onClose}>
          <CloseCircle size={15} />
        </Box>
      </Flex>
      <Box mx={20} c="#00328D">
        {children}
      </Box>
      <Flex justify={'flex-end'} gap={10} m={20}>
        <Button variant="outline" onClick={onCancel}>
          {cancelLabel}
        </Button>
        <Button onClick={onConfirm}>{confirmLabel}</Button>
      </Flex>
    </Modal>
  );
};
