import React from 'react';
import { AuthContextProps } from 'react-oidc-context';
import { StateStore, useStateStore } from 'store';

export const Logout = (props: { auth: AuthContextProps }) => {
  const { auth } = props;
  const { isAuthenticated, signoutRedirect } = auth;
  const setLogoutReason = useStateStore(
    (state: StateStore) => state.setLogoutReason
  );
  const logout = () => {
    setLogoutReason('User logged out!');
    signoutRedirect().then();
  };
  return isAuthenticated ? (
    <button type="submit" onClick={logout}>
      Logout
    </button>
  ) : null;
};

export default Logout;
