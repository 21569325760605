import { useState } from 'react';
import { Flex, Text, Modal, Button } from '@mantine/core';
import { useEditorColors } from '../../theme/useEditorColors';

interface Props {
  children?: React.ReactNode;
  onClose?: () => void;
}
export const Error = ({ children, onClose }: Props) => {
  const colors = useEditorColors().wait;
  const [opened, setOpened] = useState(true);
  const handleClose = onClose || (() => setOpened(false));

  return (
    <Modal
      onClose={() => setOpened(false)}
      opened={opened}
      withCloseButton={false}
      size="auto"
      centered
    >
      <Flex
        direction="column"
        justify="center"
        align="center"
        gap={20}
        p={60}
        c={colors.textColor}
        fz="bigger"
        fw={500}
      >
        {children || <Text size="xl">Wait ...</Text>}
        <Button onClick={handleClose}>Close</Button>
      </Flex>
    </Modal>
  );
};
