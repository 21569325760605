import React, { useState } from 'react';
import {
  AuthStore,
  BackEndStore,
  JournalStore,
  PayKeyStore,
  PayOrderStore,
  PayProjectStore,
  PaySkuStore,
  PaySubscriptionStore,
  StateStore,
  ThemeStore,
  useAuthStore,
  useBackEndStore,
  useJournalsStore,
  usePayKeyStore,
  usePayOrderStore,
  usePayProjectStore,
  usePaySkuStore,
  usePaySubscriptionStore,
  UserStoreType,
  useStateStore,
  useThemeStore,
  useUserStore,
} from 'store';
import { Select } from '@mantine/core';

type ActiveStoreType = {
  title: string;
  content: unknown;
};

const StoreDebug = () => {
  const StateStore = useStateStore((state: StateStore) => state);
  const BackEndStore = useBackEndStore((state: BackEndStore) => state);
  const ThemeStore = useThemeStore((state: ThemeStore) => state);
  const UserStore = useUserStore((state: UserStoreType) => state);
  const AuthStore = useAuthStore((state: AuthStore) => state);
  const PaySkuStore = usePaySkuStore((state: PaySkuStore) => state);
  const PayKeyStore = usePayKeyStore((state: PayKeyStore) => state);
  const PaySubscriptionStore = usePaySubscriptionStore(
    (state: PaySubscriptionStore) => state
  );
  const PayProjectsStore = usePayProjectStore(
    (state: PayProjectStore) => state
  );
  const PayOrderStore = usePayOrderStore((state: PayOrderStore) => state);
  const JournalStore = useJournalsStore((state: JournalStore) => state);

  const [activeStore, setActiveStore] = useState<ActiveStoreType>();

  const stores = [
    {
      title: 'StateStore',
      content: StateStore,
    },
    {
      title: 'BackEndStore',
      content: BackEndStore,
    },
    {
      title: 'ThemeStore',
      content: ThemeStore,
    },
    {
      title: 'UserStore',
      content: UserStore,
    },
    {
      title: 'AuthStore',
      content: AuthStore,
    },
    {
      title: 'PaySkuStore',
      content: PaySkuStore,
    },
    {
      title: 'PayKeyStore',
      content: PayKeyStore,
    },
    {
      title: 'PaySubscriptionStore',
      content: PaySubscriptionStore,
    },
    {
      title: 'PayProjectsStore',
      content: PayProjectsStore,
    },
    {
      title: 'PayOrderStore',
      content: PayOrderStore,
    },
    {
      title: 'JournalStore',
      content: JournalStore,
    },
  ];

  const storeViews = stores.map(({ title, content }) => (
    <div key={title} style={{ opacity: activeStore ? 0.3 : 1 }}>
      <p style={{ fontWeight: 'bold' }}>{title}</p>
      <p>{JSON.stringify(content)}</p>
    </div>
  ));

  const selectedStore = activeStore ? (
    <div key={`${activeStore.title}`} style={{ fontSize: '1.2em' }}>
      <p style={{ fontWeight: 'bold', marginBottom: 0 }}>{activeStore.title}</p>
      <p style={{ marginTop: 0 }}>{JSON.stringify(activeStore.content)}</p>
    </div>
  ) : (
    <p style={{ marginBottom: 30 }}>No store selected.</p>
  );

  return (
    <main style={{ marginLeft: 240, overflow: 'clip' }}>
      <h3 style={{ marginBottom: 30 }}>
        The purpose of this page is to assist in debugging store state.
      </h3>
      <Select
        onChange={(label) => {
          const clickedStore = stores.find((store) => store.title === label);
          setActiveStore(clickedStore);
        }}
        searchable
        clearable
        style={{ maxWidth: 400, marginTop: '40px 0' }}
        label="Store Selector"
        placeholder="Pick a store to see its current values"
        defaultValue="-- select a store --"
        data={stores.map(({ title }) => ({ value: title, label: title }))}
      />
      <div>
        {selectedStore}
        {storeViews}
      </div>
    </main>
  );
};
export default StoreDebug;
