import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ActionIcon, Skeleton } from '@mantine/core';
import {
  Order,
  PayKeyStore,
  PayOrderStore,
  useBackEndStore,
  usePayKeyStore,
  usePayOrderStore,
} from 'store';
import { failure, formatDollarAmount } from 'helpers';
import { useDisclosure } from '@mantine/hooks';
import moment from 'moment';
import { IconEye } from '@tabler/icons';
import { OrderModal } from './OrderModal';
import clsx from 'clsx';
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import classes from './OrdersContent.module.css';
import useSharedTableConfig from '../config-hooks/useSharedTableConfig';

type Props = {
  pageSize?: number;
};

export const OrdersContent = ({ pageSize = 13 }: Props) => {
  const [page] = useState(1);
  const [fetching, setFetching] = useState(true);

  const { orders: data, fetchOrders } = usePayOrderStore(
    (state: PayOrderStore) => state
  );
  const payKey = usePayKeyStore((state: PayKeyStore) => state.payKey);
  const xapisUrl = useBackEndStore().backend.xapisUrl;

  useEffect(() => {
    try {
      fetchOrders(payKey, { page, results: pageSize });
    } catch (error) {
      failure(error);
    } finally {
      setFetching(false);
    }
  }, [fetchOrders, payKey, xapisUrl, page, pageSize]);

  const [modalData, setModalData] = useState({});
  const [opened, { open, close }] = useDisclosure(false);

  const openModal = useCallback(
    (order: Order) => {
      setModalData(order);
      open();
    },
    [open]
  );

  const columns = useMemo<MRT_ColumnDef<Order>[]>(
    () => [
      {
        accessorFn: (row) => row,
        Cell: ({ cell }) => (
          <ActionIcon
            color="blue"
            variant="transparent"
            title="more info button"
          >
            <IconEye
              size={16}
              onClick={() => openModal(cell.getValue() as Order)}
            />
          </ActionIcon>
        ),
        enableSorting: false,
        header: '',
        id: 'row',
        size: 50,
      },
      {
        accessorKey: 'order_id',
        header: 'Order Id',
      },
      {
        id: 'created_utc',
        accessorFn: (row) => moment.utc(row.created_utc).format('MM/DD/YYYY'),
        header: 'Created',
      },
      {
        accessorKey: 'bill_to_name',
        header: 'Name',
      },
      {
        id: 'total',
        accessorFn: (row) => formatDollarAmount(row.total),
        header: 'Total',
      },
      {
        accessorKey: 'bill_to_email',
        header: 'Email',
      },
      {
        accessorKey: 'project_key',
        header: 'Project Key',
      },
      {
        accessorKey: 'payment_method',

        header: 'Payment Method',
      },
    ],
    [openModal]
  );

  const table = useMantineReactTable({
    columns,
    data,
    ...useSharedTableConfig<Order>(clsx(classes.table)),
  });

  //using MRT_Table instead of MantineReactTable if we do not want any of the toolbar features
  return (
    <>
      {opened && <OrderModal opened={opened} close={close} data={modalData} />}
      {fetching ? (
        <Skeleton h="100vh" w="100%" />
      ) : (
        <MantineReactTable table={table} />
      )}
    </>
  );
};

export default OrdersContent;
