import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import 'mantine-react-table/styles.css';
import { ActionIcon, Skeleton } from '@mantine/core';
import {
  PayProjectResponse,
  PayProjectStore,
  useBackEndStore,
  usePayProjectStore,
  useUserStore,
} from 'store';
import { failure, success } from 'helpers';
import { IconEdit } from '@tabler/icons';
import AddPayProjectDrawer from './addDrawer';
import EditPayProjectDrawer from './editDrawer';
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import clsx from 'clsx';
import classes from './PayProjectContent.module.css';
import useSharedTableConfig from '../config-hooks/useSharedTableConfig';

const data = {
  pay_project_name: '',
  pay_key: '',
  is_active: true,
  pa_client_id: '',
};

type Props = {
  pageSize?: number;
};

export const PayProjectContent = ({ pageSize = 5 }: Props) => {
  const [editDrawerOpened, setEditDrawerOpened] = useState(false);
  const [page] = useState(1);
  const [fetching, setFetching] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(data);
  const {
    payProjects: projects,
    fetchPayProjects: fetchProjects,
    createPayProject,
    updatePayProject,
  } = usePayProjectStore((state: PayProjectStore) => state);
  const xapisUrl = useBackEndStore().backend.xapisUrl;
  const user_key = useUserStore().user.user_key;

  useEffect(() => {
    setFetching(true);
    try {
      fetchProjects({ page, results: pageSize, user_key });
    } catch (error) {
      failure(error);
    } finally {
      setFetching(false);
    }
  }, [fetchProjects, xapisUrl, page, pageSize, user_key]);

  const submit = useCallback(
    async (formData: PayProjectResponse) => {
      setFetching(true);

      const environment =
        document.location.href.indexOf('localhost') > -1 ? 'test' : 'live';
      const payProjectData = {
        ...formData,
        payment_processor: 'DigitalRiver',
        environment,
        user_key,
      };

      createPayProject(payProjectData, (response) => {
        const {
          data: { message = null },
        } = response;
        message === 'created.'
          ? success(message)
          : failure({ message, name: '' } as Error);

        fetchProjects({ results: pageSize, user_key });
        setFetching(false);
      });
    },
    [createPayProject, fetchProjects, pageSize, user_key]
  );

  const update = async (formData: PayProjectResponse) => {
    setFetching(true);

    const environment =
      document.location.href.indexOf('localhost') > -1 ? 'test' : 'live';
    const payProjectData = {
      ...formData,
      payment_processor: 'DigitalRiver',
      environment,
      user_key,
    };

    const { pay_key } = formData;
    updatePayProject(pay_key, payProjectData, (response) => {
      const {
        data: { message = null },
      } = response;

      message === 'updated.'
        ? success(message)
        : failure({ message, name: '' } as Error);

      fetchProjects({ user_key });
      setEditDrawerOpened(false);
    });
    setFetching(false);
  };

  const editPayProject = (payProject: PayProjectResponse) => {
    try {
      setFormInitialValues(payProject);
      setEditDrawerOpened(true);
    } catch (error) {
      failure(error, 'missing pay key');
    }
  };

  const columns = useMemo<MRT_ColumnDef<PayProjectResponse>[]>(
    () => [
      {
        accessorFn: (row) => row,
        Cell: ({ cell }) => (
          <ActionIcon
            color="blue"
            variant="transparent"
            onClick={() =>
              editPayProject(cell.getValue() as PayProjectResponse)
            }
          >
            <IconEdit size={16} />
          </ActionIcon>
        ),
        enableSorting: false,
        header: '',
        Header: (
          <AddPayProjectDrawer create={submit} setLoading={setFetching} />
        ),
        id: 'row',
        size: 50,
      },
      {
        accessorKey: 'pay_key',
        header: 'Pay Key',
      },
      {
        accessorKey: 'is_active',
        header: 'Is active',
      },
      {
        accessorKey: 'pa_client_id',
        header: 'Project A client',
      },
      {
        accessorKey: 'pay_project_name',
        header: ' Pay Project Name',
      },
    ],
    [submit]
  );

  const table = useMantineReactTable({
    columns,
    data: projects,
    ...useSharedTableConfig<PayProjectResponse>(clsx(classes.table)),
  });

  //using MRT_Table instead of MantineReactTable if we do not want any of the toolbar features
  return (
    <>
      <EditPayProjectDrawer
        update={update}
        data={formInitialValues}
        opened={editDrawerOpened}
        setOpened={setEditDrawerOpened}
      />
      {fetching ? (
        <Skeleton h="100vh" w="100%" />
      ) : (
        <MantineReactTable table={table} />
      )}
    </>
  );
};

export default PayProjectContent;
