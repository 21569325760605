import { Flex, TextInput } from '@mantine/core';
import { CloseIcon } from '../../icons/ActionIcons';
import { FilterSegments } from './FilterSegments';
import { FilterBy } from '../../store/DashboardStore';
import { MdSearch } from 'react-icons/md';
import { useEditorColors } from '../../theme/useEditorColors';
import { SearchBy } from '../../types/editor';
import classes from './SearchSegments.module.css';

type FilterProps = {
  filterCount: (filters: FilterBy) => number;
  onChange: (text: string) => void;
  searchBy: SearchBy;
  refetchSegments: (query: URLSearchParams) => void;
};

const SearchSegments = ({
  filterCount,
  onChange,
  searchBy,
  refetchSegments,
}: FilterProps) => {
  const colors = useEditorColors();

  const ClearText = () => {
    return (
      <Flex onClick={() => onChange('')}>
        <CloseIcon size={15} />
      </Flex>
    );
  };

  return (
    <Flex gap={10}>
      <TextInput
        classNames={{
          input: classes.searchInput,
          section: classes.searchRightIcon,
        }}
        leftSection={
          <MdSearch size={20} color={colors.searchBar.placeholder} />
        }
        rightSection={searchBy.text ? <ClearText /> : null}
        placeholder="Search phrase"
        value={searchBy.text}
        onChange={(ev) => onChange(ev.currentTarget.value)}
      />
      <FilterSegments
        filterCount={filterCount}
        refetchSegments={refetchSegments}
      />
    </Flex>
  );
};

export default SearchSegments;
