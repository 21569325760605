import React from 'react';
import { Switch } from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import { useThemeStore, ThemeStore } from 'store';
import { ColorTheme } from 'store';

export const ThemeToggle = () => {
  const { setTheme, theme } = useThemeStore((state: ThemeStore) => state);
  const [value, toggle] = useToggle(['dark', 'light']);

  return (
    <Switch
      label={theme === 'light' ? 'Light Theme' : 'Dark Theme'}
      size="sm"
      checked={theme === 'light'}
      onChange={() => {
        toggle();
        setTheme(value as ColorTheme);
      }}
    />
  );
};

export default ThemeToggle;
