import { useState } from 'react';
import { Flex, Popover } from '@mantine/core';
import { useSegmentEditorContext } from '../../context/SegmentEditorProvider';
import { FilterBy } from '../../store/DashboardStore';

import { ContextFilters } from './ContextFilters';
import { FilterIcon } from '../../icons/ActionIcons';
import { useEditorColors } from '../../theme/useEditorColors';
import classes from './FilterSegments.module.css';
import { ServerFilters } from './ServerFilters';

type Props = {
  filterCount: (filters: FilterBy) => number;
  refetchSegments: (query: URLSearchParams) => void;
};

export const FilterSegments = ({ filterCount, refetchSegments }: Props) => {
  const colors = useEditorColors();

  const {
    settings: { contextMode },
  } = useSegmentEditorContext();
  const [showFilters, setShowFilters] = useState(false);

  return (
    <Popover
      withinPortal
      position="top-end"
      opened={showFilters}
      onChange={setShowFilters}
      closeOnEscape
      shadow="md"
      arrowSize={15}
      arrowOffset={20}
      offset={5}
    >
      <Popover.Target>
        <Flex
          align="center"
          onClick={() => setShowFilters(!showFilters)}
          bg={showFilters ? colors.filterMenu.button : 'transparent'}
          className={classes.filterButton}
        >
          <FilterIcon />
        </Flex>
      </Popover.Target>
      <Popover.Dropdown className={classes.filterDropdown}>
        {contextMode ? (
          <ContextFilters
            setShowFilters={setShowFilters}
            filterCount={filterCount}
          />
        ) : (
          <ServerFilters
            setShowFilters={setShowFilters}
            refetchSegments={refetchSegments}
          />
        )}
      </Popover.Dropdown>
    </Popover>
  );
};
