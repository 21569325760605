import PositionTopLeft from './images/position-top-left.svg';
import PositionTopRight from './images/position-top-right.svg';
import PositionBottomLeft from './images/position-bottom-left.svg';
import PositionBottomRight from './images/position-bottom-right.svg';
import LightLanguageSelector from './images/light-language-selector.svg';
import DarkLanguageSelector from './images/dark-language-selector.svg';
import { asciiToHex } from 'helpers';

export const DARK_THEME = 'dark' as const;
export const LIGHT_THEME = 'light' as const;
const SELECTOR_THEMES = [DARK_THEME, LIGHT_THEME];
export type LanguageSelectorTheme = (typeof SELECTOR_THEMES)[number];

export const TOP_POSITION = 'top' as const;
export const BOTTOM_POSITION = 'bottom' as const;
export const LEFT_POSITION = 'left' as const;
export const RIGHT_POSITION = 'right' as const;
export type TopBottomPosition = typeof TOP_POSITION | typeof BOTTOM_POSITION;
export type LeftRightPosition = typeof LEFT_POSITION | typeof RIGHT_POSITION;
export type LanguageSelectorPosition =
  `${TopBottomPosition}_${LeftRightPosition}`;

export const TOP_LEFT_POSITION = `${TOP_POSITION}_${LEFT_POSITION}` as const;
export const TOP_RIGHT_POSITION = `${TOP_POSITION}_${RIGHT_POSITION}` as const;
export const BOTTOM_LEFT_POSITION =
  `${BOTTOM_POSITION}_${LEFT_POSITION}` as const;
export const BOTTOM_RIGHT_POSITION =
  `${BOTTOM_POSITION}_${RIGHT_POSITION}` as const;
const SELECTOR_POSITIONS: LanguageSelectorPosition[] = [
  TOP_LEFT_POSITION,
  TOP_RIGHT_POSITION,
  BOTTOM_LEFT_POSITION,
  BOTTOM_RIGHT_POSITION,
];

export const getLanguageSelectorPositions = (
  languageSelectorPosition: LanguageSelectorPosition
) => {
  const [topBottom = BOTTOM_POSITION, leftRight = LEFT_POSITION] =
    languageSelectorPosition.split('_');
  return { topBottom, leftRight } as {
    topBottom: TopBottomPosition;
    leftRight: LeftRightPosition;
  };
};

export type AppearanceOption = {
  position: LanguageSelectorPosition;
  src: string;
  alt: string;
};

export const themeOptions = [
  {
    theme: LIGHT_THEME,
    src: LightLanguageSelector,
    alt: 'Light theme language selector',
  },
  {
    theme: DARK_THEME,
    src: DarkLanguageSelector,
    alt: 'Dark theme language selector',
  },
];
export const positionOptions: AppearanceOption[] = [
  {
    position: TOP_LEFT_POSITION,
    src: PositionTopLeft,
    alt: 'Select top left widget position',
  },
  {
    position: TOP_RIGHT_POSITION,
    src: PositionTopRight,
    alt: 'Select top right widget position',
  },
  {
    position: BOTTOM_LEFT_POSITION,
    src: PositionBottomLeft,
    alt: 'Select bottom left widget position',
  },
  {
    position: BOTTOM_RIGHT_POSITION,
    src: PositionBottomRight,
    alt: 'Select bottom right widget position',
  },
];

const getCustomCSSComment = (
  topBottom: TopBottomPosition = BOTTOM_POSITION,
  leftRight: LeftRightPosition = LEFT_POSITION,
  theme: LanguageSelectorTheme = DARK_THEME
) =>
  `/* LANGUAGE_SELECTOR_POSITION: "${topBottom}_${leftRight}" LANGUAGE_SELECTOR_THEME: "${theme}" */`;

export const getCustomCSSPreferences = (customCSS: string) => {
  if (!customCSS) {
    return { position: undefined, theme: undefined };
  }

  // Must reflect the same output as getCustomCSSComment(...), because that comment will be saved in the customCSS for XAPIS
  const regExp = new RegExp(
    `LANGUAGE_SELECTOR_POSITION: "([^"]*)" LANGUAGE_SELECTOR_THEME: "([^"]*)"`
  );
  const [, position, theme] = customCSS.match(regExp) || [];
  return {
    position,
    theme,
  } as {
    position: LanguageSelectorPosition | undefined;
    theme: LanguageSelectorTheme | undefined;
  };
};

const getThemeColors = (theme: LanguageSelectorTheme = DARK_THEME) => {
  switch (theme) {
    case LIGHT_THEME:
      return {
        backgroundColor: 'white',
        highlightColor: 'rgba(255, 255, 255, .7)',
        textColor: 'black',
      };

    case DARK_THEME:
    default:
      return {
        backgroundColor: 'black',
        highlightColor: 'rgba(0, 0, 0, .5)',
        textColor: 'white',
      };
  }
};

const selectorPresets = (() => {
  const presets: {
    position: LanguageSelectorPosition;
    theme: LanguageSelectorTheme;
    hex: string;
  }[] = [];
  SELECTOR_POSITIONS.forEach((position) => {
    const [topBottom, leftRight] = position.split('_');
    SELECTOR_THEMES.forEach((theme) => {
      presets.push({
        position,
        theme,
        hex: asciiToHex(
          getCustomCSS(
            topBottom as TopBottomPosition,
            leftRight as LeftRightPosition,
            theme
          )
        ),
      });
    });
  });
  return presets;
})();

export const isPresetCss = (hexedCustomCss: string) =>
  selectorPresets.some((p) => p.hex === hexedCustomCss);

export const getPresetCssPreferences = (
  hexedCustomCss: string
): {
  position: LanguageSelectorPosition | null;
  theme: LanguageSelectorTheme | null;
} => {
  const preset = selectorPresets.find((p) => p.hex === hexedCustomCss);
  return preset
    ? { position: preset.position, theme: preset.theme }
    : { position: null, theme: null };
};

export const getPresetCssHex = (
  position: LanguageSelectorPosition,
  theme: LanguageSelectorTheme
) => {
  const preset = selectorPresets.find(
    (p) => p.position === position && p.theme === theme
  );
  return preset?.hex || '';
};

export function getCustomCSS(
  topBottom: TopBottomPosition = BOTTOM_POSITION,
  leftRight: LeftRightPosition = LEFT_POSITION,
  theme: LanguageSelectorTheme = DARK_THEME
) {
  const { backgroundColor, highlightColor, textColor } = getThemeColors(theme);
  const verticalPosition = topBottom === TOP_POSITION ? 'top' : 'bottom';
  const horizontalPosition = leftRight === LEFT_POSITION ? 'left' : 'right';

  // Do NOT indent the string below as it will ruin the CSS output
  return `${getCustomCSSComment(topBottom, leftRight, theme)}
.GLGO-language-selector {
  position: fixed;
  ${verticalPosition}: 0;
  ${horizontalPosition}: 20px;
  z-index: 9999999999;
  width: 175px;
  white-space: nowrap;
}

.oljs-select-selected {
  background: ${backgroundColor};
}

.oljs-select-selected:after {
  position: absolute;
  ${verticalPosition}: 40%;
  right: 8px;
  z-index: 9999999999;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: ${textColor} transparent transparent transparent;
  transform: rotateZ(${verticalPosition === TOP_POSITION ? '0deg' : '180deg'});
  transition: 0.5s;
}

.oljs-select-selected.oljs-select-arrow-active:after {
  border-color: transparent transparent ${textColor} transparent;
  ${verticalPosition}: 22%;
}

.oljs-select-items div,.oljs-select-selected {
  color: ${textColor} !important;
  font-family: Arial !important;
  font-size: small !important;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-right-width: 6px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis !important;
  line-height: 1.5em !important;
}

.oljs-select-items {
  position: absolute;
  max-height: 300px;
  overflow-y: auto;
  background: ${highlightColor};
  width: 175px;
  scrollbar-color: ${backgroundColor};
  ${verticalPosition}: 100%;
  right: 0;
  z-index: 9999999999;
}

.oljs-select-hide {
  display: none;
}

.oljs-select-items div:hover, .oljs-same-as-selected {
  background: ${backgroundColor} !important;
}`;
}
