import { useEffect } from 'react';
import { AuthContextProps } from 'react-oidc-context';
import {
  AuthStore,
  useAuthStore,
  UserStoreType,
  useUserStore,
  xapisEnv,
} from 'store';
import { notifications } from '@mantine/notifications';

export const useTPAuth = (auth: AuthContextProps) => {
  const {
    fetchUser,
    user: { user_key },
  } = useUserStore((state: UserStoreType) => state);
  const { setToken } = useAuthStore((state: AuthStore) => state);
  const { access_token = '', profile: { email = '' } = {} } = auth.user || {
    access_token: '',
  };

  useEffect(() => {
    if (access_token) {
      xapisEnv.setToken(access_token);
    }
  }, [access_token, setToken]);

  useEffect(() => {
    if (email && !user_key) {
      fetchUser(email);
    }
  }, [email, user_key, fetchUser]);

  useEffect(() => {
    /* addAccessTokenExpiring() returns a callback:
     ** Return the event and its callback / cleanup function
     * Documentation: https://github.com/authts/react-oidc-context#documentation
     */
    return auth.events.addAccessTokenExpiring(() => {
      auth
        .signinSilent()
        .then((userData) => {
          if (userData === null) {
            auth.signoutRedirect().finally(() => {
              notifications.show({
                message: 'Unable to renew user authentication at this time.',
                color: 'red',
              });
            });
          }
        })
        .catch((error) => {
          auth.signoutRedirect().finally(() => {
            console.error('Auth renewal error:', error);
            notifications.show({
              message: 'Unable to renew user authentication at this time.',
              color: 'red',
            });
          });
        });
    });
  }, [auth.events, auth.signinSilent]);
};

export default useTPAuth;
