import * as React from 'react';
import { Drawer } from '@mantine/core';
import PayProjectForm from './PayProjectForm';
import { PayProjectResponse } from 'store';

export const EditDrawer = (props: {
  update: (formData: PayProjectResponse) => Promise<void>;
  data: PayProjectResponse;
  opened: boolean;
  setOpened: (value: ((prevState: boolean) => boolean) | boolean) => void;
}) => {
  const { update, data, opened, setOpened } = props;
  const submit = async (newProject: PayProjectResponse) => {
    await update(newProject);
  };

  return (
    <Drawer
      opened={opened}
      onClose={() => setOpened(false)}
      title="Edit Pay Project"
      padding="xl"
      size="xl"
    >
      <PayProjectForm submit={submit} editMode initialValues={data} />
    </Drawer>
  );
};

export default EditDrawer;
