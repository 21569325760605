import * as React from 'react';
import { useAuth } from 'react-oidc-context';
import { StateStore, useStateStore } from 'store';

const SilentRenew = () => {
  const auth = useAuth();
  const { redirectUrl } = useStateStore((state: StateStore) => state);

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated && !auth.isLoading) {
    document.location.href = redirectUrl;
  }
  return <div />;
};
export default SilentRenew;
