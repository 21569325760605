import React, { createContext, useContext, useEffect, useState } from 'react';

export interface GLGOUserContext {
  xapisUser: User;
  userProjects: ProjectKey[];
  setXapisUser: (user: User) => void;
  setUserProjects: (projects: ProjectKey[]) => void;
}

export const glgoUser = {
  user: {} as User,

  projectType(pKey: string) {
    return (
      this.user?.project_keys?.find((p: ProjectKey) => p.project_key === pKey)
        ?.project_type || ''
    );
  },

  userType() {
    return this.user?.user_type || '';
  },

  getUser() {
    return this.user;
  },

  set(user: User) {
    this.user = user;
  },
};

export const XapisUserContext = createContext<GLGOUserContext>({
  xapisUser: { user_key: '' },
  userProjects: [],
  setXapisUser: () => {},
  setUserProjects: () => {},
});

export const useUserProvider = () => {
  const context = useContext(XapisUserContext);
  if (!context) {
    throw new Error('useUserProvider must be used within UserProvider');
  }
  return context;
};

export function UserProvider({
  children,
  user,
}: {
  children: React.ReactNode;
  user: User | null;
}) {
  const [xapisUser, setXapisUser] = useState<User>({ user_key: '' });

  const updateUser = (u: User) => {
    setXapisUser(u);
    glgoUser.set(u);
  };

  const setUserProjects = (projects: ProjectKey[]) => {
    updateUser({ ...xapisUser, project_keys: projects }); //could user either xapisUser or getUser() since they should always match
  };

  useEffect(() => {
    if (user) {
      updateUser(user);
    }
  }, [user]);

  return (
    <XapisUserContext.Provider
      value={{
        xapisUser,
        userProjects: xapisUser.project_keys || [],
        setXapisUser: updateUser,
        setUserProjects,
        // useGetFirstProjectKey,
      }}
    >
      {children}
    </XapisUserContext.Provider>
  );
}
