const routerConstants = {
  /* Page Routes */
  PAGE_NOT_FOUND: '*',
  PAGE_ROOT: '/',
  PAGE_SUBSCRIPTIONS: '/subscriptions',
  PAGE_ORDERS: '/orders',
  PAGE_JOURNAL: '/journal',
  PAGE_PROJECTS: '/projects',
  PAGE_LOGIN_REDIRECT: '/login_redirect',
  PAGE_LOGOUT_REDIRECT: 'logout_redirect',
  PAGE_SILENT_RENEW: '/silent_renew',
  PAGE_STORE_DEBUG: '/groovy',
  /* Page Titles */
  PAGE_TITLE_ROOT: 'SKUs',
  PAGE_TITLE_SUBSCRIPTIONS: 'Subscriptions',
  PAGE_TITLE_ORDERS: 'Orders',
  PAGE_TITLE_JOURNAL: 'Journal',
  PAGE_TITLE_PROJECTS: 'Projects',
};

export default routerConstants;
