import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Skeleton } from '@mantine/core';
import {
  PayKeyStore,
  PaySubscriptionStore,
  Subscription,
  useBackEndStore,
  usePayKeyStore,
  usePaySubscriptionStore,
} from 'store';
import { failure } from 'helpers';
import moment from 'moment';
import ordinal from 'ordinal';
import classes from './SubscriptionsContent.module.css';
import clsx from 'clsx';
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import useSharedTableConfig from '../config-hooks/useSharedTableConfig';

type Props = {
  pageSize?: number;
};

const renderAnniversary = (data: Subscription) => {
  const anniversary =
    data.subscription_type !== 'MONTHLY'
      ? `${data.anniversary_day_nice} of ${data.anniversary_month_abbrev}`
      : `${ordinal(data.anniversary_day)} of every month`;
  return data.sku === 'GLGO-FREE' || data.sku === 'GLGO-LIVE-FREE'
    ? 'Not Applicable'
    : anniversary;
};

export const SubscriptionsContent = ({ pageSize = 13 }: Props) => {
  const [fetching, setFetching] = useState(true);
  const [page] = useState(1);

  const { subscriptions: data, fetchSubscriptions } = usePaySubscriptionStore(
    (state: PaySubscriptionStore) => state
  );
  const payKey = usePayKeyStore((state: PayKeyStore) => state.payKey);
  const xapisUrl = useBackEndStore().backend.xapisUrl;

  useEffect(() => {
    try {
      setFetching(true);
      fetchSubscriptions(payKey, { page, results: pageSize }, '', {});
    } catch (error) {
      failure(error);
    } finally {
      setFetching(false);
    }
  }, [fetchSubscriptions, payKey, xapisUrl, page, pageSize]);

  const columns = useMemo<MRT_ColumnDef<Subscription>[]>(
    () => [
      {
        accessorKey: 'bill_to_name',
        header: 'Name',
      },
      {
        accessorKey: 'bill_to_email',
        header: 'Email',
      },
      {
        accessorKey: 'sku',
        header: 'SKU',
      },
      {
        accessorKey: 'subscription_type',
        header: 'Type',
      },
      {
        accessorKey: 'created_utc',
        accessorFn: (row) => moment.utc(row.created_utc).format('MM/DD/YYYY'),
        header: 'Created',
      },
      {
        accessorKey: 'sub',
        accessorFn: (row) => renderAnniversary(row),
        header: 'Anniversary Date',
      },
      {
        accessorKey: 'is_canceled',
        header: 'Renews',
      },
      {
        accessorKey: 'billing_agreement_key',
        header: 'Billing Key',
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data,
    ...useSharedTableConfig<Subscription>(clsx(classes.table)),
  });

  //using MRT_Table instead of MantineReactTable if we do not want any of the toolbar features
  return fetching ? (
    <Skeleton h="100vh" w="100%" />
  ) : (
    <MantineReactTable table={table} />
  );
};
export default SubscriptionsContent;
