import { MRT_RowData, MRT_TableOptions } from 'mantine-react-table';

const useSharedTableConfig = <T>(
  className: string
): Partial<MRT_TableOptions<T extends MRT_RowData ? T : never>> => {
  return {
    enableColumnActions: false,
    enableTopToolbar: false,
    mantineTableProps: {
      className,
      highlightOnHover: false,
      striped: 'odd',
      withColumnBorders: true,
      withRowBorders: true,
      withTableBorder: false,
    },
    mantinePaginationProps: {
      rowsPerPageOptions: ['5', '10', '20', '50'],
      withEdges: false, //note: changed from `showFirstLastButtons` in v1.0
    },
  };
};

export default useSharedTableConfig;
