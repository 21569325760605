import * as React from 'react';
import {
  JournalPage,
  OrdersPage,
  PayProjectPage,
  SkuPage,
  SubscriptionsPage,
} from 'pages';
import PostLogin from './PostLogin';
import SilentRenew from './SilentRenew';
import PostLogout from './PostLogout';
import ErrorView from './ErrorView';
import RC from './routerConstants';
import StoreDebug from './StoreDebug';

export const authorizedRoutes = [
  {
    path: RC.PAGE_ROOT,
    element: () => <SkuPage />,
  },
  {
    path: RC.PAGE_SUBSCRIPTIONS,
    element: () => <SubscriptionsPage />,
  },
  {
    path: RC.PAGE_ORDERS,
    element: () => <OrdersPage />,
  },
  {
    path: RC.PAGE_JOURNAL,
    element: () => <JournalPage />,
  },
  {
    path: RC.PAGE_PROJECTS,
    element: () => <PayProjectPage />,
  },
  {
    path: RC.PAGE_LOGIN_REDIRECT,
    element: () => <PostLogin />,
  },
  {
    path: RC.PAGE_SILENT_RENEW,
    element: () => <SilentRenew />,
  },
  {
    path: RC.PAGE_STORE_DEBUG,
    element: () => <StoreDebug />,
  },
  {
    path: RC.PAGE_NOT_FOUND,
    element: () => <ErrorView />,
  },
];

export const unauthorizedRoutes = [
  {
    path: RC.PAGE_LOGOUT_REDIRECT,
    element: () => <PostLogout />,
  },
  {
    path: RC.PAGE_NOT_FOUND,
    element: () => <ErrorView />,
  },
];

const routeExports = {
  authorizedRoutes,
  unauthorizedRoutes,
};

export default routeExports;
