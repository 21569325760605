import React from 'react';
import { Button, Flex } from '@mantine/core';
import classes from './FooterButtons.module.css';

type Props = {
  loading?: boolean;
  disabled?: boolean;
  defaultFormSubmit?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  onBack?: () => void;
  onConfirm?: () => void;
};

const FooterButtons = ({
  loading,
  disabled,
  defaultFormSubmit,
  ...props
}: Props) => {
  const { onCancel, onSave, onBack, onConfirm } = props || {};

  return (
    <Flex columnGap="1rem" justify="flex-end">
      <Button
        w="7rem"
        h="2.75rem"
        fz="1rem"
        radius={30}
        c="text.8"
        className={classes.backButton}
        disabled={loading}
        onClick={() => {
          onCancel && onCancel();
          onBack && onBack();
        }}
      >
        {onCancel ? 'Cancel' : 'Back'}
      </Button>
      <Button
        w="7rem"
        h="2.75rem"
        fz="1rem"
        radius={30}
        type="submit"
        className={classes.nextButton}
        disabled={loading || disabled}
        onClick={() => {
          onSave && onSave();
          onConfirm && onConfirm();
        }}
      >
        {defaultFormSubmit || onSave ? 'Save' : 'Continue'}
      </Button>
    </Flex>
  );
};

export default FooterButtons;
