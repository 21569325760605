import React from 'react';
import { Modal, Button, Text, Container, Box } from '@mantine/core';
import classes from './UnsavedChangesModal.module.css';
import { CloseIcon } from '../../icons/ActionIcons';

type UnsavedChangesModalProps = {
  open: boolean;
  onCancel: () => void;
  onDiscard: () => void;
  onSave: () => void;
};

export const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({
  open,
  onCancel,
  onDiscard,
  onSave,
}) => (
  <Modal
    opened={open}
    onClose={onCancel}
    size={540}
    withCloseButton={false}
    centered
  >
    <Container
      size="md"
      mt={20}
      style={{ display: 'flex', justifyContent: 'flex-end' }}
    >
      <div style={{ cursor: 'pointer' }} onClick={onCancel}>
        <CloseIcon />
      </div>
    </Container>
    <Box px={30}>
      <Container size="md" mb={20}>
        <Text ta="left" className={classes.title}>
          Leave Page?
        </Text>
        <Text ta="left" className={classes.message}>
          You have unsaved changes. Do you want to discard or save them?
        </Text>
      </Container>
      <Container
        size="md"
        mt={42}
        mb={30}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={onCancel} variant="outline">
          Cancel
        </Button>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '10px',
          }}
        >
          <Button
            variant="outline"
            style={{ borderColor: '#FF6C6C' }}
            onClick={onDiscard}
          >
            Discard
          </Button>
          <Button onClick={onSave} style={{ marginRight: 10 }}>
            Save
          </Button>
        </div>
      </Container>
    </Box>
  </Modal>
);
