import { useContext } from 'react';
import { Box, Flex, Text } from '@mantine/core';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';

import { isFormattingTag } from '../Jliff/jliffFunctions';
import { Action, ActionBar } from '../Generic/ActionBar';
import { useEditorColors } from '../../theme/useEditorColors';

import { Insertable } from './InsertElement';
import { InsertMore } from './InsertMore';

import {
  BoldIcon,
  CommandIcon,
  ItalicIcon,
  RevealTagsIcon,
  UnderlineIcon,
} from '../../icons/ActionIcons';
import { getTextStyle } from '../Jliff/Jliff.style';
import QuickAction from '../Generic/QuickAction';
import React from 'react';

const icons = {
  b: <BoldIcon />,
  i: <ItalicIcon />,
  u: <UnderlineIcon />,
} as const;

const labels = {
  b: 'Bold',
  i: 'Italic',
  u: 'Underline',
} as const;

const quickTags = Object.keys(icons);

type ActionsProps = {
  isTarget: boolean;
  isSelected?: boolean;
  addElement?: (insert: Insertable) => void;
  removeElement?: (tag: string) => void;
  haveTags?: boolean;
  activeTags?: string[];
  showAllTags: boolean;
  setShowAllTags: (showAllTags: boolean) => void;
};

export const TopBar = ({
  isTarget,
  isSelected: isSegmentSelected,
  haveTags,
  addElement,
  removeElement,
  activeTags = [],
  showAllTags,
  setShowAllTags,
}: ActionsProps) => {
  const colors = useEditorColors();
  const { disableTagEdit, viewOnly } =
    useContext(SegmentEditorContext).settings;
  const additionalTags =
    activeTags
      ?.filter((tag) => !quickTags.includes(tag))
      .reduce(
        (acc, tag) => (acc.includes(tag) ? acc : [...acc, tag]),
        [] as string[]
      ) || [];

  const quickAction = (tag: string) => {
    if (activeTags?.includes(tag)) {
      removeElement && removeElement(tag);
    } else if (addElement) {
      addElement({ type: 'tag', values: [tag] });
    }
  };

  const QuickActionLabel = ({
    iconKey,
    icon,
  }: {
    iconKey: string;
    icon: React.ReactNode;
  }) => {
    const label = labels[iconKey as keyof typeof labels];
    if (label) {
      return (
        <Flex align="center" justify="center" rowGap={2} wrap="wrap">
          <Text c="inherit">{label}</Text>
          <Flex w="100%" align="center" justify="center" columnGap={2}>
            <Flex
              w={18}
              h={18}
              align="center"
              justify="center"
              bg="gray.8"
              style={{ borderRadius: 2 }}
            >
              <CommandIcon size={10} />
            </Flex>
            <Flex
              w={18}
              h={18}
              align="center"
              justify="center"
              bg="gray.8"
              style={{ borderRadius: 2 }}
            >
              {icon}
            </Flex>
          </Flex>
        </Flex>
      );
    }
    return <></>;
  };

  const actions: Action[] = quickTags.map((tag) => {
    const icon = icons[tag as keyof typeof icons];
    return {
      icon: icon,
      onClick: () => quickAction(tag),
      disabled: disableTagEdit || viewOnly,
      active: activeTags?.includes(tag),
      label: <QuickActionLabel iconKey={tag} icon={icon} />,
    };
  });

  const additionalActions: Action[] = additionalTags.map((tag) => {
    const isFormatting = isFormattingTag(tag);
    return {
      icon: (
        <Box
          opacity={isFormatting ? 1 : 0.7}
          style={{
            cursor: isFormatting ? 'pointer' : 'default',
            ...getTextStyle(colors.jliffEditor, [tag]),
          }}
        >
          {tag.charAt(0).toUpperCase() + tag.substring(1, 2)}
        </Box>
      ),
      onClick: () => quickAction(tag),
      disabled: disableTagEdit || viewOnly,
      active: isFormattingTag(tag),
    };
  });

  return (
    <Flex
      h={40}
      align="center"
      justify={!isTarget ? 'space-between' : 'flex-end'}
      p={!isTarget ? (isSegmentSelected ? '0 30px' : '0 50px') : '0 30px'}
      columnGap={10}
      bg="blue.0"
      style={{
        borderTopLeftRadius: !isTarget ? 10 : 0,
        borderTopRightRadius: !isTarget ? 0 : 10,
      }}
    >
      {haveTags ? (
        <QuickAction
          width={30}
          height={30}
          icon={<RevealTagsIcon />}
          backgroundColor={colors.jliffActions.background}
          isActive={showAllTags}
          onClick={() => setShowAllTags(!showAllTags)}
          label="Show all Tags"
        />
      ) : (
        <div></div>
      )}
      {!!addElement && (
        <Flex gap={20}>
          {additionalTags.length > 0 && (
            <ActionBar actions={additionalActions} />
          )}
          <ActionBar actions={actions} />
          <InsertMore addElement={addElement} />
        </Flex>
      )}
    </Flex>
  );
};
