// TODO: Remove using this store (using for development)
import SpiderTreeNode from '../SpiderTreeNode';
import { xapisService } from '../service-wrappers/xapisService';
import { filterRootTree } from '../spiderTreeFilters';
import { getURLSearchParamsString } from 'helpers';

let userKey = '';

export const setUserKey = (key: string) => {
  userKey = key;
};

export const getUserKey = () => userKey;

export const Xapis = {
  Glossary: {
    get: async (keys: string, limit: number = 0): Promise<GlossaryResponse> =>
      xapisService
        .get(`/Glossary/${keys || ''}?user_key=${userKey}&limit=${limit}`)
        .then((res) => ({ status: res.status, glossary: res.data }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
    delete: async (
      keys: string,
      hash: string
    ): Promise<{ status: number; data: string }> => {
      const key = Array.isArray(keys) ? keys.join('|') : keys;

      return xapisService
        .delete(
          `/Glossary/${key || ''}?user_key=${userKey}&source_text_hash=${hash}`
        )
        .then((res) => {
          return { status: res.status, data: res.data?.message };
        })
        .catch((e) => ({
          status: e.response?.status || 500,
          data: e.response?.data?.message || e.message,
        }));
    },
    post: async (
      keys: string | string[],
      payload: GlossaryAPIPayload | GlossaryAPIPayload[]
    ): Promise<{ status: number; data: string }> => {
      const key = Array.isArray(keys) ? keys.join('|') : keys;
      const data = Array.isArray(payload)
        ? payload.map((item) => ({ ...item, translation_source: userKey }))
        : [{ ...payload, translation_source: userKey }];
      return xapisService
        .post(`/Glossary/${key || ''}?user_key=${userKey}`, data)
        .then((res) => {
          return { status: res.status, data: res.data?.message };
        })
        .catch((e) => ({
          status: e.response?.status || 500,
          data: e.response?.data?.message || e.message,
        }));
    },
  },
  ContentPage: {
    get: async (
      tKey: string,
      limit: number = 0
    ): Promise<{ status: number; data: ContentPageAPIResponse | string }> =>
      xapisService
        .get(`/ContentPage/${tKey}?user_key=${userKey}&limit=${limit}`)
        .then((res) => ({ status: res.status, data: res.data }))
        .catch((e) => ({
          status: e.response?.status || 500,
          data: e.response?.data?.message || e.message,
        })),
  },
  User: {
    get: async (
      email: string
    ): Promise<{ status: number; data: User; message?: string }> =>
      xapisService
        .get(`/User/${email}`)
        .then((res) => ({ status: res.status, data: res.data }))
        .catch((e) => ({
          status: e.response?.status || 500,
          data: { user_key: '' },
          message: e.response?.data?.message || e.message,
        })),
    post: async (
      data: Partial<User>
    ): Promise<{ status: number; data: User }> =>
      xapisService
        .post(`/User?user_key=${userKey}`, data)
        .then((res) => ({
          status: res.status,
          data: res.data,
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          data: { user_key: '' },
          message: e.response?.data?.message || e.message,
        })),
    put: async (
      selectedUserKey: string,
      data: Partial<User>
    ): Promise<{ status: number; data: User }> =>
      xapisService
        .put(`/User/${selectedUserKey}?user_key=${userKey}`, data)
        .then((res) => ({
          status: res.status,
          data: res.data,
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          data: { user_key: '' },
          message: e.response?.data?.message || e.message,
        })),
    delete: async (
      selectedUserKey: string
    ): Promise<{ status: number; data: User }> =>
      xapisService
        .delete(`/User/${selectedUserKey}?user_key=${userKey}`)
        .then((res) => ({
          status: res.status,
          data: res.data,
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          data: e.response?.data?.message || e.message,
        })),
  },
  Project: {
    get: async (
      projectKey: string,
      isDetails?: boolean
    ): Promise<ProjectResponse> =>
      xapisService
        .get(
          `/Project/${projectKey}?user_key=${userKey}&details=${isDetails ? 1 : 0}`
        )
        .then((res) => ({ status: res.status, project: res.data }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
          project: undefined,
        })),
    put: async (
      projectKey: string,
      data: Partial<ProjectKey>
    ): Promise<ProjectResponse> =>
      xapisService
        .put(`/Project/${projectKey}?user_key=${userKey}`, data)
        .then((res) => ({ status: res.status, project: res.data }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
          project: undefined,
        })),
  },
  BlockSegmentData: {
    post: async (
      blocksData: BlockData[] | null,
      translationKey: string
    ): Promise<{ status: number; data: BlockSegment | string }> =>
      xapisService
        .post(`/BlockSegmentData/${translationKey}`, blocksData)
        .then((res) => ({
          status: res.status,
          data: res.data.blocks,
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          data: e.response?.data?.message || e.message,
        })),
  },
  LanguageCodes: {
    get: async () =>
      xapisService
        .get('LanguageCode/ty')
        .then((res) => ({
          status: res.status,
          language_codes: res.data.language_codes || [],
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  ProjectTranslation: {
    get: async (projectKey: string, translationKey?: string) => {
      return xapisService
        .get(
          `ProjectTranslation/${projectKey}${translationKey ? '/' + translationKey : ''}?user_key=${userKey}`
        )
        .then((res) => ({
          status: res.status,
          data: res.data || {},
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          data: e.response?.data?.message || e.message,
        }));
    },
    post: async (projectKey: string, data: Partial<TranslationKey>) =>
      xapisService
        .post(`ProjectTranslation/${projectKey}?user_key=${userKey}`, data)
        .then((res) => ({
          status: res.status,
          data: res.data || {},
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
    put: async (
      projectKey: string,
      translationKey: string,
      data: Partial<TranslationKey>
    ): Promise<ProjectTranslationResponse> =>
      xapisService
        .put(
          `ProjectTranslation/${projectKey}/${translationKey}?user_key=${userKey}`,
          data
        )
        .then((res) => ({
          status: res.status,
          data: res.data || {},
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  SpiderTree: {
    get: async (
      translationKey: string,
      contentUrlHash?: string
    ): Promise<SpiderTreeResponse> =>
      xapisService
        .get(
          `SpiderTree/${translationKey}${contentUrlHash ? `/${contentUrlHash}` : ''}?user_key=${userKey}`
        )
        .then(({ status = 0, data: { data = [] } = {} }) => {
          const tree = !contentUrlHash ? filterRootTree(data) : data;
          return {
            status,
            tree,
          };
        })
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  ConfigHistory: {
    get: async (translationKey: string, type: string) =>
      xapisService
        .get<{ revisions: RevisionItem[] }>(
          `ConfigHistory/${translationKey}/${type}?user_key=${userKey}&limit=0`
        )
        .then(({ status = 0, data: { revisions = [] } = {} }) => ({
          status,
          revisions,
        }))
        .catch((e) => ({
          revisions: [] as RevisionItem[],
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  Group: {
    post: async (groupData: GroupData) =>
      xapisService
        .post(`/Group?user_key=${userKey}`, groupData)
        .then((res) => ({
          status: res.status,
          data: res.data.users || {},
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  GroupUser: {
    post: async (groupKey: string, addUserKeys: string[]) =>
      xapisService
        .post(
          `/GroupUser/${groupKey}/${addUserKeys.join(',')}?user_key=${userKey}`
        )
        .then((res) => ({
          status: res.status,
          data: res.data.users || {},
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
    delete: async (groupKey: string, removeUserKeys: string[]) =>
      xapisService
        .delete(
          `/GroupUser/${groupKey}/${removeUserKeys.join(',')}?user_key=${userKey}`
        )
        .then((res) => ({
          status: res.status,
          data: res.data.users || {},
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          message: e.response?.data?.message || e.message,
        })),
  },
  Metrics: {
    get: async (query?: Record<string, string>) =>
      xapisService
        .get(`/Metrics?user_key=${userKey}&${getURLSearchParamsString(query)}`)
        .then((res) => ({
          status: res.status,
          data: res.data || [],
        }))
        .catch((e) => ({
          status: e.response?.status || 500,
          mesage: e.response?.data?.message || e.message,
          data: undefined,
        })),
  },
};

interface DefaultResponse {
  status: number;
  message?: string;
}

export interface GlossaryResponse extends DefaultResponse {
  glossary?: GlossaryAPIResponse;
}
export interface ContentPageResponse extends DefaultResponse {
  data?: ContentPageAPIResponse;
}

export interface ProjectResponse extends DefaultResponse {
  project: ProjectKey | undefined;
}

export interface LanguageCodeResponse extends DefaultResponse {
  language_codes?: LanguageCode[];
}

export interface ProjectTranslationResponse extends DefaultResponse {
  data?: TranslationKey;
}

export interface SpiderTreeResponse extends DefaultResponse {
  tree?: SpiderTreeNode[];
}

export interface ConfigHistoryResponse extends DefaultResponse {
  revisions: RevisionItem[];
}
