import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import 'mantine-react-table/styles.css';
import classes from './JournalContent.module.css';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import {
  type MRT_ColumnDef,
  useMantineReactTable,
  MantineReactTable,
} from 'mantine-react-table';
import {
  useJournalsStore,
  JournalStore,
  usePayKeyStore,
  PayKeyStore,
  useBackEndStore,
  JournalWCurrencyCode,
} from 'store';
import { failure, formatDollarAmount } from 'helpers';
import { Skeleton } from '@mantine/core';
import useSharedTableConfig from '../config-hooks/useSharedTableConfig';

type Props = {
  pageSize?: number;
};

export const JournalContent = ({ pageSize = 13 }: Props) => {
  const [page] = useState(1);
  const [fetching, setFetching] = useState(true);

  const { journals: data, fetchJournals } = useJournalsStore(
    (state: JournalStore) => state
  );
  const payKey = usePayKeyStore((state: PayKeyStore) => state.payKey);
  const xapisUrl = useBackEndStore().backend.xapisUrl;

  useEffect(() => {
    setFetching(true);
    try {
      fetchJournals(payKey, { page, results: pageSize });
    } catch (error) {
      failure(error);
    } finally {
      setFetching(false);
    }
  }, [fetchJournals, payKey, xapisUrl, page, pageSize]);

  const columns = useMemo<MRT_ColumnDef<JournalWCurrencyCode>[]>(
    () => [
      {
        accessorKey: 'month',
        header: 'Month',
      },
      {
        id: 'subtotal',
        accessorFn: (row) => formatDollarAmount(row.subtotal),
        header: 'Subtotal',
      },
      {
        id: 'tax',
        accessorFn: (row) => formatDollarAmount(row.tax),
        header: 'Tax',
      },
      {
        id: 'total',
        accessorFn: (row) => formatDollarAmount(row.total),
        header: 'Total',
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data,
    ...useSharedTableConfig<JournalWCurrencyCode>(clsx(classes.table)),
  });

  //using MRT_Table instead of MantineReactTable if we do not want any of the toolbar features
  return fetching ? (
    <Skeleton h="100vh" w="100%" />
  ) : (
    <MantineReactTable table={table} />
  );
};

export default JournalContent;
