import { FormEvent, useCallback, useState } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Flex,
  Group,
  Image,
  Modal,
  Radio,
  Switch,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import classes from './GlossaryRuleForm.module.css';
import { useForm } from '@mantine/form';
import RedMinus from '../../gosetup/images/RedMinusSymbol.svg';
import TranslateAs from '../../gosetup/images/TranslateAsGlossaryIcon.svg';
import AddNote from '../../gosetup/images/AddNote.svg';
import BluePlus from '../../gosetup/images/BluePlusSymbol.svg';
import CaseSensitive from '../../gosetup/images/CaseSensitiveIcon.svg';
import OverrideMT from '../../gosetup/images/OverrideMTSymbol.svg';
import Warning from '../../gosetup/images/WarningAmber.svg';
import { DeleterPromise, Xapis } from 'store';
import isSuccessStatus from 'helpers/utils/isSuccessStatus';
import success from 'helpers/success';
import {
  NEVER_TRANSLATE,
  TRANSLATE_AS,
  NEVER_TRANSLATE_AS,
  RuleType,
  glossaryRules,
} from './glossaryConstants';
import { randomId } from '@mantine/hooks';
import MultiSelectAll from '../../../../navigation/dashboard/MultiSelectAll';
import Failure from 'helpers/failure';
import GLA from '../../../googleAnalytics';
import { notifications } from '@mantine/notifications';

const defaultFormValues: FormValues = {
  rule: NEVER_TRANSLATE,
  is_mt: true,
  is_case_sensitive: false,
  translation_keys: [],
  source_text: '',
  targetTextList: [{ targetText: '', id: randomId() }],
  comment: '',
};

const rulesLables = {
  [NEVER_TRANSLATE]: {
    label: 'Never Translate',
    tip: 'The term you enter will not be translated',
  },
  [TRANSLATE_AS]: {
    label: 'Translate As',
    tip: 'The term you enter should be translated a certain way',
  },
  [NEVER_TRANSLATE_AS]: {
    label: `Don't Translate As`,
    tip: (
      <Text>
        The term you enter should{' '}
        <Text span fs="italic">
          not
        </Text>{' '}
        be translated a certain way
      </Text>
    ),
  },
};

export type FormValues = {
  rule: RuleType;
  is_mt: boolean;
  is_case_sensitive: boolean;
  translation_keys: string[];
  source_text: string;
  targetTextList: {
    targetText: string;
    id: string;
  }[];
  comment: string;
};

const buttonText = { Add: 'Add Glossary Rule', Edit: 'Edit' };
const MAX_COMMENT_SIZE = 250; // Maximum column size for "comment" in XAPIS_GLOSSARY table
type Props = {
  type: 'Add' | 'Edit';
  targetsOptions?: {
    value: string;
    label: string;
  }[];
  initialValues?: FormValues;
  buttonProps?: ButtonProps;
};

export const GlossaryRuleForm = ({
  type = 'Add',
  targetsOptions = [],
  initialValues = defaultFormValues,
  buttonProps = {},
}: Props) => {
  const colors = useMantineTheme().colors;
  const [opened, { open, close }] = useDisclosure(false);
  const [openNotes, setOpenNotes] = useState(false);

  const form = useForm<FormValues>({
    initialValues,
    validate: {
      rule: (value) => !value && 'Must include a rule',
      source_text: (value) => !value && 'Term must be included',
      translation_keys: (values) =>
        values.length === 0 && 'Must include at least 1 language',
      comment: (value) =>
        value.length > MAX_COMMENT_SIZE &&
        `Cannot exceed ${MAX_COMMENT_SIZE} characters`,
      targetTextList: {
        targetText: (value, formValues) => {
          if (formValues.rule !== NEVER_TRANSLATE) {
            return !value.trim() ? 'Translation must be included' : null;
          }
        },
      },
    },
  });

  const onRuleChange = (rule: string) => {
    if (!glossaryRules.includes(rule as RuleType)) {
      Failure(`Could not recognize rule: "${rule}"`);
      return;
    }
    const isMt = rule !== NEVER_TRANSLATE_AS;
    form.setFieldValue('rule', rule as RuleType);
    form.setFieldValue('is_mt', isMt);

    if (
      (rule === NEVER_TRANSLATE_AS || rule === TRANSLATE_AS) &&
      form.values.targetTextList.length === 0
    ) {
      form.setFieldValue('targetTextList', [
        { targetText: '', id: randomId() },
      ]);
    }
  };

  const rule = form?.values?.rule || NEVER_TRANSLATE;
  const isMtOverrideDisabled = rule === NEVER_TRANSLATE_AS;

  const closeModal = useCallback(() => {
    close();
    setOpenNotes(false);
  }, [close]);

  const openModal = () => {
    form.setValues(initialValues);
    open();
  };

  const clearPretranslateCache = async (translation_keys: string[]) => {
    return Promise.all([
      ...translation_keys.map((tKey) => {
        return DeleterPromise(`Pretranslate/${tKey}`);
      }),
    ]);
  };

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    form.onSubmit((formValues: FormValues) => {
      const values: Partial<FormValues> = { ...formValues };
      const { translation_keys = [], targetTextList } = values;
      const target_text = targetTextList?.map((t) => t.targetText) || [];
      delete values.targetTextList;
      const payload = {
        ...values,
        target_text,
      } as GlossaryAPIPayload;

      closeModal();
      return clearPretranslateCache(translation_keys)
        .then(() => {
          return Xapis.Glossary.post(translation_keys, payload).then(
            (response) => {
              if (isSuccessStatus(response.status)) {
                success(`Glossary Rule saved.`);
                if (response.data) {
                  GLA.analyticsEvent(
                    GLA.EVENT_ACTION_FORM_SUBMISSION,
                    GLA.EVENT_CATEGORY_GLOSSARY,
                    GLA.EVENT_LABEL_GLOSSARY_RULE_FORM
                  );
                }
              }
            }
          );
        })
        .catch(() => {
          notifications.show({
            message: 'Unable to add Glossary rule at this time',
          });
        })
        .finally(() => {
          closeModal();
        });
    })(event);
  };

  const addTargetText = () =>
    form.insertListItem('targetTextList', { targetText: '', key: randomId() });
  return (
    <>
      <Button
        {...buttonProps}
        onClick={openModal}
        style={{ borderRadius: 3 }}
        fz={12}
      >
        {buttonText[type]}
      </Button>
      <Modal
        id="pw-translations-add-glossary-rule"
        size="lg"
        opened={opened}
        onClose={closeModal}
        padding="3rem"
      >
        <Modal.Body p={0}>
          <Title order={3} mx="auto" pb="1rem">
            {`${type} Glossary Rule`}
          </Title>
        </Modal.Body>
        <Flex direction="column">
          <Divider />
          <form className={classes.ruleForm} onSubmit={onFormSubmit}>
            <Text mt="1rem" fw={600}>
              PICK A RULE
            </Text>
            <Radio.Group
              mt="1rem"
              name="ruleType"
              {...form.getInputProps('rule')}
              onChange={onRuleChange}
            >
              <Group data-testid="pw-translations-add-glossary-rule-tooltip">
                {glossaryRules.map((type) => (
                  <Tooltip
                    key={type}
                    label={rulesLables[type as keyof typeof rulesLables].tip}
                  >
                    <Radio
                      classNames={{
                        root: classes.radioRoot,
                        label: classes.radioLabel,
                        body: classes.radioBody,
                        inner: classes.radioInner,
                        radio: classes.radioRadio,
                      }}
                      style={{
                        border:
                          rule === type
                            ? `1px solid ${colors.text3[2]}`
                            : `1px solid ${colors.badge[0]}`,
                      }}
                      value={type}
                      label={
                        rulesLables[type as keyof typeof rulesLables].label
                      }
                    />
                  </Tooltip>
                ))}
              </Group>
            </Radio.Group>
            <Flex
              direction="column"
              rowGap="1rem"
              mt="2rem"
              data-testid="pw-translations-add-glossary-rule-options-tooltip"
            >
              <Text fw={600}>Options</Text>
              <Flex justify="space-between">
                <Text {...(isMtOverrideDisabled && { c: 'text.9' })}>
                  Override Machine Translation
                </Text>
                <Tooltip
                  label={
                    isMtOverrideDisabled
                      ? 'Not available for this rule type'
                      : 'This rule will override the machine translation engine'
                  }
                  zIndex={999}
                >
                  <Flex columnGap="1rem">
                    <Box>
                      <Image src={OverrideMT} />
                    </Box>
                    <Switch
                      color="background2.2"
                      checked={form.getInputProps('is_mt')?.value}
                      disabled={isMtOverrideDisabled}
                      {...form.getInputProps('is_mt')}
                      classNames={{
                        input: classes.switchInput,
                        track: classes.switchTrack,
                      }}
                    />
                  </Flex>
                </Tooltip>
              </Flex>
              <Flex justify="space-between">
                <Text>Case Sensitive</Text>
                <Tooltip
                  label="This rule will only apply where the case matches what you enter"
                  zIndex={999}
                >
                  <Flex columnGap="1rem">
                    <Box>
                      <Image src={CaseSensitive} />
                    </Box>
                    <Switch
                      color="background2.2"
                      checked={form.getInputProps('is_case_sensitive')?.value}
                      classNames={{
                        input: classes.switchInput,
                        track: classes.switchTrack,
                      }}
                      {...form.getInputProps('is_case_sensitive')}
                    />
                  </Flex>
                </Tooltip>
              </Flex>
            </Flex>
            <Text mt="1rem" fw={600}>
              SELECT YOUR LANGUAGES
            </Text>
            <MultiSelectAll
              data={targetsOptions}
              targetOptions={targetsOptions}
              onChange={(values: string[]) =>
                form.setFieldValue('translation_keys', values)
              }
              error={form.errors['translation_keys']}
              type={type}
              withCheckIcon={false}
            />
            <Text mt="1rem" fw={600}>
              ENTER YOUR TERM
            </Text>
            <Flex direction="column" bg="gray.1" py=".25rem">
              <TextInput
                placeholder="Source"
                disabled={type === 'Edit'}
                {...form.getInputProps('source_text')}
              />
              {rule === TRANSLATE_AS && (
                <>
                  <Divider
                    color="black"
                    size="sm"
                    mt="1rem"
                    mb="1rem"
                    labelPosition="center"
                    label={
                      <Box>
                        <Image src={TranslateAs} />
                      </Box>
                    }
                  />
                  <TextInput
                    placeholder="Translation"
                    {...form.getInputProps('targetTextList.0.targetText')}
                  />
                </>
              )}
              {rule === NEVER_TRANSLATE_AS && (
                <>
                  <Divider
                    color="black"
                    size="sm"
                    mt="1rem"
                    mb="1rem"
                    labelPosition="center"
                    label={
                      <Box>
                        <Image src={Warning} />
                      </Box>
                    }
                  />
                  {form.values.targetTextList.map((item, index) => (
                    <Group key={index} mt="xs">
                      <TextInput
                        placeholder="Disallowed term"
                        mt=".125rem"
                        style={{ flexGrow: 1 }}
                        {...form.getInputProps(
                          `targetTextList.${index}.targetText`
                        )}
                      />
                      {index === 0 ? (
                        <Box
                          onClick={addTargetText}
                          pr="1rem"
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <Image src={BluePlus} />
                        </Box>
                      ) : (
                        <Box
                          onClick={() =>
                            form.removeListItem('targetTextList', index)
                          }
                          pr="1rem"
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <Image src={RedMinus} />
                        </Box>
                      )}
                    </Group>
                  ))}
                </>
              )}
            </Flex>
            <Flex
              mt="1rem"
              onClick={() => setOpenNotes(!openNotes)}
              style={{
                cursor: 'pointer',
              }}
            >
              <Box>
                <Image src={AddNote} />
              </Box>
              <Text fw={600} fz=".875rem" ml="2rem" c="text.8">
                Additional Notes
              </Text>
            </Flex>
            {openNotes && (
              <>
                <Textarea
                  autosize
                  minRows={2}
                  maxRows={4}
                  {...form.getInputProps('comment')}
                />
                <Text ta="end" fw={600} fz=".875rem" ml="2rem" c="text.8">
                  {form.values.comment.length}/250
                </Text>
                <Text ta="center" fw={600} c="red">
                  {form?.values?.comment?.length > 250 &&
                    'Exceeded character limit.'}
                </Text>
              </>
            )}
            <Divider mt="1rem" />
            <Flex justify="center">
              <Button
                disabled={!form.isValid() || !form.isDirty()}
                mt="1rem"
                type="submit"
              >
                {`${type} Rule`}
              </Button>
            </Flex>
          </form>
        </Flex>
      </Modal>
    </>
  );
};
