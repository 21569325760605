import React from 'react';
import {
  IconDatabase,
  IconList,
  IconMessages,
  IconAlertCircle,
  IconGitPullRequest,
  IconDeer,
} from '@tabler/icons';
import RC from './routerConstants';

export const navLinks = [
  {
    icon: <IconGitPullRequest size={16} />,
    color: 'blue',
    label: RC.PAGE_TITLE_ROOT,
    path: RC.PAGE_ROOT,
  },

  {
    icon: <IconAlertCircle size={16} />,
    color: 'teal',
    label: RC.PAGE_TITLE_SUBSCRIPTIONS,
    path: RC.PAGE_SUBSCRIPTIONS,
  },
  {
    icon: <IconMessages size={16} />,
    color: 'violet',
    label: RC.PAGE_TITLE_ORDERS,
    path: RC.PAGE_ORDERS,
  },
  {
    icon: <IconDatabase size={16} />,
    color: 'grape',
    label: RC.PAGE_TITLE_JOURNAL,
    path: RC.PAGE_JOURNAL,
  },
  {
    icon: <IconList size={16} />,
    color: 'blue',
    label: RC.PAGE_TITLE_PROJECTS,
    path: RC.PAGE_PROJECTS,
  },
  {
    icon: <IconDeer size={16} />,
    color: 'red',
    label: 'Debug',
    path: RC.PAGE_STORE_DEBUG,
  },
];

export default navLinks;
