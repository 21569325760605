import * as React from 'react';
import { useState } from 'react';
import { Drawer, UnstyledButton } from '@mantine/core';
import { IconPlus } from '@tabler/icons';
import PayProjectAddForm from './PayProjectForm';
import { PayProjectResponse } from 'store';

export const AddDrawer = ({
  create,
  setLoading,
}: {
  create: (data: PayProjectResponse) => Promise<void>;
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void;
}) => {
  const [opened, setOpened] = useState(false);
  const submit = async (data: PayProjectResponse) => {
    await create(data);
    setOpened(false);
  };

  return (
    <>
      <Drawer
        opened={opened}
        onClose={() => {
          setLoading(false);
          setOpened(false);
        }}
        title="Add Pay Project"
        padding="xl"
        size="xl"
      >
        <PayProjectAddForm submit={submit} />
      </Drawer>
      <UnstyledButton
        style={{ cursor: 'pointer', fontSize: '0px', color: 'transparent' }}
        onClick={() => setOpened(true)}
      >
        <IconPlus size={16} color="green" />
        Add Project
      </UnstyledButton>
    </>
  );
};

export default AddDrawer;
