import { useEffect, useState } from 'react';
import { Box, Flex, Text, useMantineTheme } from '@mantine/core';
import { useEditorColors } from '../../theme/useEditorColors';
import {
  NotificationType,
  useDashboardActions,
  useNotifiction,
} from '../../store/DashboardStore';

import {
  Success,
  AlertIcon,
  InfoIcon,
  WarningIcon,
} from '../../icons/IndicatorIcons';

const icons = {
  success: <Success />,
  error: <AlertIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
};

export const Message = () => {
  const theme = useMantineTheme();
  const { resetNotification } = useDashboardActions();
  const { text, type } = useNotifiction();
  const colors = useEditorColors().notification;
  const color = colors[type] || theme.colors.blue[7];
  const [show, setShow] = useState(0);

  useEffect(() => {
    if (text) {
      setShow(1);
      setTimeout(() => {
        setShow(0);
        setTimeout(() => {
          resetNotification();
        }, 1000);
      }, 3000);
    }
  }, [text, resetNotification]);

  return text ? (
    <Flex
      align="center"
      gap={2}
      color={color}
      p="2px 5px"
      bg="transparent"
      opacity={show}
      style={{
        zIndex: 999,
        outline: `2px solid ${color}`,
        borderRadius: 32,
        transition: 'opacity 0.8s linear',
      }}
    >
      <Box w={20} h={20}>
        {icons[type]}
      </Box>
      <Text fz={14}>{text}</Text>
    </Flex>
  ) : (
    <></>
  );
};
