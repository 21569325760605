import React from 'react';
import { AuthContextProps, useAuth } from 'react-oidc-context';
import { StateStore, useStateStore, xapisEnv } from 'store';
import RC from './routerConstants';

const PostLogin = () => {
  const auth: AuthContextProps = useAuth();
  const {
    isAuthenticated,
    isLoading,
    activeNavigator,
    error,
    signinRedirect,
    user,
  } = auth;

  const { redirectUrl } = useStateStore((state: StateStore) => state);
  if (isAuthenticated && !isLoading) {
    xapisEnv.setToken(user?.access_token || 'AccessTokenUndefined');
    document.location.href = redirectUrl || RC.PAGE_ROOT;
  }

  switch (activeNavigator) {
    case 'signinSilent':
      return <div>Post Login: Signing you in...</div>;
    case 'signoutRedirect':
      return <div>Post Login: Signing you out...</div>;
    default:
  }

  if (isLoading) {
    return <div>Post Login: Loading...</div>;
  }

  if (error) {
    const { message } = error;
    signinRedirect().then();
    return <div>Post Login: Oops... {message}</div>;
  }
  return (
    <header className="App-header">
      <div>Post Login: Logging in...</div>
    </header>
  );
};
export default PostLogin;
