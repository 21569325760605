import { User, UserManager } from 'oidc-client-ts';
import { getOidcConfig } from './oidcConfig';
import { xapisEnv } from 'store';
import { shouldSkipSSO } from '../shouldSkipSSO';

const { ssoUrl } = xapisEnv.getHost || {};
const oidcConfig = getOidcConfig(ssoUrl);
// ssoUrl === LIVE_SSO_URL ? productionOidcConfig : stagingOidcConfig;
export interface IAuthService {
  getUser(): Promise<User | null>;
  login(): Promise<void>;
  logout(): Promise<void>;
  signinSilent(): Promise<User | null>;
  signinCallback(url: string): Promise<User>;
}

const isGLGODesktop = import.meta.env.VITE_IS_GLGO_DESKTOP === true;

export class AuthService implements IAuthService {
  private static _instance: AuthService;
  public static getInstance(): AuthService {
    if (!AuthService._instance) {
      AuthService._instance = new AuthService();
    }
    return AuthService._instance;
  }
  public _userManager: UserManager;
  private _userPromise: Promise<User | null>;
  private _resolveUserPromise: ((user: User | null) => void) | null = null;

  constructor() {
    this._userManager = new UserManager(oidcConfig);
    this._userPromise = new Promise((resolve) => {
      this._resolveUserPromise = resolve;
    });

    if (
      isGLGODesktop &&
      window &&
      window.UserManager &&
      window.UserManager.checkTokenStatus
    ) {
      const {
        onTokenStatus,
        onSSORedirect,
        onLogoutFromMain,
        checkTokenStatus,
      } = window.UserManager;
      onTokenStatus(this.processTokenStatus.bind(this));

      onSSORedirect(this.onSSORedirect.bind(this));
      onLogoutFromMain(() => {
        this._userManager.signoutRedirect().catch((error) => {
          console.error(error);
        });
      });

      const sessionTokenStatus = sessionStorage.getItem('TOKEN_STATUS');
      if (sessionTokenStatus) this.processTokenStatus(sessionTokenStatus);
      checkTokenStatus();
    }
  }

  private processTokenStatus(tokenStatus: string) {
    sessionStorage.setItem('TOKEN_STATUS', tokenStatus);
    if (tokenStatus.startsWith('valid')) {
      const [, email, accessToken] = tokenStatus.split(' ');
      const user = {
        access_token: accessToken,
        profile: { email },
      };
      this._resolveUserPromise!(user as unknown as User);
    } else if (tokenStatus.startsWith('invalid')) {
      this._resolveUserPromise!(null);
    }
  }

  private async onSSORedirect(url: string) {
    try {
      await this.signinCallback(url);
      const user = await this._userManager.getUser();
      if (user) {
        window.UserManager.saveToken(
          user.refresh_token,
          user.access_token,
          user.expires_at,
          user.profile.email
        );
      }
    } catch (error) {
      console.error('Error in onSSORedirect:', error);
    }
  }

  public async getUser() {
    if (shouldSkipSSO() && sessionStorage.getItem('user-email')) {
      return {
        access_token: 'OneViewToSeeTheWorld',
        profile: {
          email: sessionStorage.getItem('user-email'),
        },
      } as User;
    }

    if (isGLGODesktop) {
      return this._userPromise;
    }
    return this._userManager.getUser();
  }

  public login() {
    return this._userManager.signinRedirect();
  }

  public signinSilent() {
    return this._userManager.signinSilent();
  }

  public logout() {
    return this._userManager.signoutRedirect();
  }

  public signinCallback(url: string) {
    return this._userManager.signinRedirectCallback(url);
  }
}
