import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Flex, TextInput, Title } from '@mantine/core';
import { useEditorColors } from '../../theme/useEditorColors';
import { CloseIcon } from '../../icons/ActionIcons';
import { MdSearch } from 'react-icons/md';
import classes from './SearchSegments.module.css';

const serverParams = ['s', 'u'] as const;
type ServerParams = (typeof serverParams)[number];

type Props = {
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
  refetchSegments: (query: URLSearchParams) => void;
};

export const ServerFilters = ({ setShowFilters, refetchSegments }: Props) => {
  const colors = useEditorColors();

  const [searchParams, setSearchParams] = useSearchParams();
  const [urlFilter, setUrlFilter] = useState(searchParams.get('u') || '');

  const handleApplyFilters = () => {
    const updatedParams = new URLSearchParams(searchParams);
    urlFilter ? updatedParams.set('u', urlFilter) : updatedParams.delete('u');
    setSearchParams(updatedParams);
    setShowFilters(false);
    refetchSegments(updatedParams);
  };

  const handleClearFilters = () => {
    setUrlFilter('');
  };

  const FilterName = ({ label }: { label: string }) => (
    <Title order={6} py={8}>
      {label}
    </Title>
  );

  return (
    <Flex
      direction={'column'}
      style={{ borderRadius: 15 }}
      gap={20}
      px={20}
      py={20}
      bg="#FFFFFF"
    >
      <Box>
        <FilterName label="Page URL" />
        <TextInput
          classNames={{
            input: classes.searchInput,
            section: classes.searchRightIcon,
          }}
          leftSection={
            <MdSearch size={20} color={colors.searchBar.placeholder} />
          }
          rightSection={
            <Flex onClick={() => setUrlFilter('')}>
              <CloseIcon size={15} />
            </Flex>
          }
          placeholder="Search URL"
          value={urlFilter}
          onChange={(ev) => setUrlFilter(ev.currentTarget.value)}
        />
      </Box>
      <Flex w="100%" justify={'space-between'} gap={10}>
        <Button variant="outline" onClick={handleClearFilters}>
          Clear All
        </Button>
        <Button onClick={handleApplyFilters}>
          <Box>Apply</Box>
        </Button>
      </Flex>
    </Flex>
  );
};
