import * as React from 'react';
import { Drawer } from '@mantine/core';
import { BaseSku } from 'store';
import EditForm from './SKUForm';

export const EditDrawer = ({
  update,
  data,
  opened,
  setOpened,
}: {
  update: (skuData: BaseSku) => Promise<void>;
  data: BaseSku;
  opened: boolean;
  setOpened: (bool: boolean) => void;
}) => {
  const submit = async (skuData: BaseSku) => {
    await update(skuData);
  };

  return (
    <Drawer
      opened={opened}
      onClose={() => setOpened(false)}
      title="Edit SKU"
      padding="xl"
      size="xl"
    >
      <EditForm submit={submit} editMode initialValues={data} />
    </Drawer>
  );
};

export default EditDrawer;
