import * as React from 'react';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import './App.css';
import {
  AppShell,
  NavigationHeader,
  PayProjectSelector,
  SideNavigation,
  ThemeToggle,
} from 'ui';
import {
  DebugStore,
  ThemeStore,
  useDebugStore,
  UserStoreType,
  useThemeStore,
  useUserStore,
} from 'store';
import cheet from 'cheet.js';
import { useDisclosure } from '@mantine/hooks';
import logoLight from './logo-light.svg';
import logoDark from './logo-dark.svg';
import { authorizedRoutes } from './routes';
import navigationLinks from './navLinks';
import packageJson from '../package.json';

const TestingApp = () => {
  const auth = useAuth();
  const { theme: colorScheme } = useThemeStore((state: ThemeStore) => state);
  const {
    fetchUser,
    user: { user_key },
  } = useUserStore((state: UserStoreType) => state);
  const { debug, setDebug } = useDebugStore((state: DebugStore) => state);

  const [opened, { toggle, close }] = useDisclosure(false);

  const logo = colorScheme === 'light' ? logoLight : logoDark;

  /* c8 ignore next 4 */
  (cheet as unknown as (str: string, cb: () => void) => void)(
    'ctrl d b g',
    () => {
      setDebug(!debug);
    }
  );

  useEffect(() => {
    if (!user_key) {
      fetchUser('phillip.meckley@transperfect.com');
    }
  }, [fetchUser, user_key]);

  let actualLinks = [...navigationLinks];
  if (!debug) {
    actualLinks = actualLinks.filter((l) => l.label !== 'Debug');
  }

  if (user_key)
    return (
      <AppShell routes={authorizedRoutes}>
        <SideNavigation auth={auth} navLinks={actualLinks}>
          <ThemeToggle />
        </SideNavigation>
        <NavigationHeader
          logo={logo}
          navLinks={navigationLinks}
          opened={opened}
          toggle={toggle}
          close={close}
          version={`Build ${packageJson.version}`}
        >
          <PayProjectSelector close={close} />
        </NavigationHeader>
      </AppShell>
    );

  return <></>;
};

export default TestingApp;
