import * as React from 'react';
import { useEffect } from 'react';
import { Select } from '@mantine/core';
import {
  PayKeyStore,
  PayProjectResponse,
  PayProjectStore,
  useBackEndStore,
  usePayKeyStore,
  usePayProjectStore,
  useUserStore,
} from 'store';

export const PayProjectSelector = ({ close }: { close: VoidFunction }) => {
  const user_key = useUserStore().user.user_key;
  const projects = usePayProjectStore(
    (state: PayProjectStore) => state.payProjects
  );
  const fetchPayProjects = usePayProjectStore(
    (state: PayProjectStore) => state.fetchPayProjects
  );
  const { payKey, setPayKey } = usePayKeyStore((state: PayKeyStore) => state);
  const {
    backend: { xapisUrl },
  } = useBackEndStore();

  useEffect(() => {
    fetchPayProjects({ user_key });
  }, [fetchPayProjects, xapisUrl, user_key]);

  if (projects.length > 0) {
    return (
      <Select
        onChange={(label) => {
          if (!label) return;
          setPayKey(label);
          close();
        }}
        placeholder="Pick one"
        defaultValue={payKey}
        data={projects.map((p: PayProjectResponse) => ({
          label: p.pay_project_name,
          value: p.pay_key,
        }))}
      />
    );
  }
  return null;
};

export default PayProjectSelector;
