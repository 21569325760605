import { Flex, Text } from '@mantine/core';
import { LockIcon } from '../../icons/IndicatorIcons';
import { useEditorColors } from '../../theme/useEditorColors';
import classes from './SegmentHeader.module.css';

const SegmentsHeader = ({ target }: { target: TranslationKey }) => {
  const colors = useEditorColors();

  const source = getSourceName(target);
  const translation = getTargetName(target);
  const isPrivate = !target.is_live;

  function getTargetName(target: TranslationKey | undefined) {
    if (!target) return 'Target';
    return target.target_lang_name || target.target_lang_code || 'Target';
  }
  function getSourceName(target: TranslationKey | undefined) {
    if (!target) return 'Source';
    return target.source_lang_name || target.source_lang_code || 'Source';
  }
  return (
    <Flex className={classes.segmentHeader}>
      <Flex w="50%" align="center" justify="center" pl={50}>
        <Text fz={15} fw={600} c="blue.8" truncate>Source - {source}</Text>
      </Flex>
      <Flex w="50%" pl={10} pr={50} columnGap={10} align="center">
        <Flex
          w="100%"
          columnGap={15}
          align="center"
          justify="center"
          direction="row"
        >
          <Text fz={15} fw={600} c="blue.8" truncate>Translation - {translation}</Text>
          {isPrivate && (
            <>
              <Flex align={'center'} gap={6}>
                <LockIcon size={15} />
                <Text size="xs" c={colors.jliffEditor.privateColor}>
                  Private
                </Text>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SegmentsHeader;
