import React, { createContext, useContext, useState } from 'react';

export const RecommendedThresholdContext = createContext<{
  threshold: number;
  setThreshold: React.Dispatch<React.SetStateAction<number>>;
}>({
  threshold: 0,
  setThreshold: () => {},
});

export const useRecommendedThresholdContext = () => {
  const context = useContext(RecommendedThresholdContext);
  if (!context) {
    throw new Error(
      'useRecommendedThresholdContext must be used within a ThresholdProvider'
    );
  }
  return context;
};

export const RecommendedThresholdProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [threshold, setThreshold] = useState(0);

  return (
    <RecommendedThresholdContext.Provider value={{ threshold, setThreshold }}>
      {children}
    </RecommendedThresholdContext.Provider>
  );
};

export default RecommendedThresholdProvider;
