import React from 'react';
import { Box, Card, Divider, Flex, Text, Tooltip } from '@mantine/core';
import { useDeploymentContext } from '../DeploymentContextProvider';

const DefaultQueryMethod = () => {
  const {
    loading,
    activeProject: {
      deployment_name: deploymentName = '',
      origin_name: domain = '',
    } = {},
    targets = [],
  } = useDeploymentContext();
  const parsedDomain = domain.replaceAll('www.', '');

  return (
    <Card p={0} style={{ boxShadow: 'none' }} opacity={loading ? 0.5 : 1}>
      <Flex pl="1.5rem">
        <Box w="30%" pt="1.65rem" pr="0.5rem">
          <Text fw={600}>Query Parameter</Text>
        </Box>
        <Flex w="70%" h="5rem" pl="0.5rem" align="center">
          <Text truncate>{deploymentName}</Text>
        </Flex>
      </Flex>
      <Divider size="xs" orientation="horizontal" color="divider.1" />
      <Flex pl="1.5rem" py="0.5rem">
        <Box w="30%" pt="1rem" pr="0.5rem">
          <Text fw={600}>Query Value</Text>
        </Box>
        <Box w="70%" pl="0.5rem">
          {targets.map(
            ({ target_lang_name = '', deployment_value = '' }, index) => {
              const isLastIndex = index === targets.length - 1;

              return (
                <Box key={target_lang_name}>
                  <Flex h="5rem">
                    <Flex w="70%" align="center" pr="1rem">
                      <Tooltip
                        withinPortal
                        position="bottom-start"
                        events={{ hover: true, touch: true, focus: false }}
                        label={
                          <Text c="text.2" fz="0.9rem" fw={400} truncate span>
                            {`${parsedDomain}/?${deploymentName}=`}
                            <Text c="text.2" fw={700} truncate span>
                              {deployment_value}
                            </Text>
                          </Text>
                        }
                      >
                        <Text truncate>
                          {target_lang_name}
                          <br />
                          <Text c="text.3" fz="0.9rem" fw={400} truncate span>
                            {`${parsedDomain}/?${deploymentName}=`}
                            <Text c="text.3" fw={700} truncate span>
                              {deployment_value}
                            </Text>
                          </Text>
                        </Text>
                      </Tooltip>
                    </Flex>
                    <Flex w="30%" align="center" pl="1rem">
                      <Text truncate>{deployment_value}</Text>
                    </Flex>
                  </Flex>
                  {!isLastIndex && (
                    <Divider
                      size="xs"
                      orientation="horizontal"
                      color="divider.1"
                    />
                  )}
                </Box>
              );
            }
          )}
        </Box>
      </Flex>
    </Card>
  );
};

export default DefaultQueryMethod;
