import React from 'react';
import { Box, Card, Divider, Flex, Text } from '@mantine/core';
import { getDeploymentTargets } from '../utils';
import { useDeploymentContext } from '../DeploymentContextProvider';

const DefaultDomainMethod = () => {
  const { loading, targets = [] } = useDeploymentContext();
  const deploymentTargets = getDeploymentTargets(targets);

  return (
    <Card p={0} style={{ boxShadow: 'none' }} opacity={loading ? 0.5 : 1}>
      <Flex h="100%" pl="1.5rem" py="0.5rem">
        <Box w="20%" pt="1.5rem" pr="0.5rem">
          <Text fw={600}>Language</Text>
        </Box>
        <Box w="80%" pl="0.5rem">
          {deploymentTargets.map(
            ({ target_lang_name = '', deployment_value = [] }, targetIndex) => {
              const isLastIndex = targetIndex === deploymentTargets.length - 1;
              const hasMultipleValues = deployment_value.length > 1;

              return (
                <Box key={targetIndex}>
                  <Flex py={hasMultipleValues ? '1.15rem' : 0}>
                    <Flex
                      w="40%"
                      h={hasMultipleValues ? '2.65rem' : '5rem'}
                      align="center"
                      pr="1rem"
                    >
                      <Text truncate>{target_lang_name}</Text>
                    </Flex>
                    <Flex
                      w="60%"
                      direction="column"
                      rowGap="0.5rem"
                      justify={hasMultipleValues ? 'flex-start' : 'center'}
                      px="1rem"
                    >
                      {deployment_value.map((value: string, valueIndex) => (
                        <Flex
                          key={`${targetIndex}-${value}-${valueIndex}`}
                          h={hasMultipleValues ? '2.65rem' : '5rem'}
                          align="center"
                        >
                          <Text truncate>{value}</Text>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                  {!isLastIndex && (
                    <Divider
                      size="xs"
                      orientation="horizontal"
                      color="divider.1"
                    />
                  )}
                </Box>
              );
            }
          )}
        </Box>
      </Flex>
    </Card>
  );
};

export default DefaultDomainMethod;
