import * as React from 'react';
import { useState } from 'react';
import { Drawer, UnstyledButton } from '@mantine/core';
import { BaseSku } from 'store';
import { IconPlus } from '@tabler/icons';
import SKUAddForm from './SKUForm';

export const AddDrawer = ({
  create,
  setLoading,
}: {
  create: (skuData: BaseSku) => Promise<void>;
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void;
}) => {
  const [opened, setOpened] = useState(false);
  const submit = async (data: BaseSku) => {
    await create(data);
    setOpened(false);
  };

  return (
    <>
      <Drawer
        opened={opened}
        onClose={() => {
          setLoading(false);
          setOpened(false);
        }}
        title="Add SKU"
        padding="xl"
        size="xl"
      >
        <SKUAddForm submit={submit} />
      </Drawer>
      <UnstyledButton
        style={{ cursor: 'pointer', fontSize: '0px', color: 'transparent' }}
        onClick={() => {
          setOpened(true);
          setLoading(true);
        }}
      >
        <IconPlus size={16} color="green" />
        Add Sku
      </UnstyledButton>
    </>
  );
};

export default AddDrawer;
