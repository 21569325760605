import React from 'react';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import TestingApp from './TestingApp';
import AuthWrapper from './AuthWrapper';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Notifications } from '@mantine/notifications';
import { MantineProvider } from '@mantine/core';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const { search } = window.location;
const isTestMode = search.includes('testing=groovy');

if (isTestMode) {
  const tokenKey = sessionStorage.getItem('TOKEN_KEY');
  if (tokenKey)
    sessionStorage.setItem(
      tokenKey,
      JSON.stringify({
        access_token: 'OneViewToSeeTheWorld',
        token_type: 'Bearer',
      })
    );

  root.render(
    <AuthWrapper>
      <BrowserRouter>
        <MantineProvider>
          <TestingApp />
        </MantineProvider>
      </BrowserRouter>
    </AuthWrapper>
  );
} else {
  root.render(
    <AuthWrapper>
      <MantineProvider defaultColorScheme="light">
        <Notifications />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MantineProvider>
    </AuthWrapper>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
