import * as React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { AppShell as MantineAppShell } from '@mantine/core';

type RouteEl = {
  element: React.FunctionComponent;
  path: string;
};

type AppShellProps = {
  routes: RouteEl[];
  children?: React.ReactNode;
};

export const AppShell = ({ routes, children = undefined }: AppShellProps) => {
  return (
    <>
      <MantineAppShell pb={0}>
        {children !== undefined && children}
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.element />}
            />
          ))}
        </Routes>
        <Outlet />
      </MantineAppShell>
    </>
  );
};

export default AppShell;
