const globalRouterConstants = {
  PAGE_ROOT: '/',
};

export const GRC = globalRouterConstants;

// Specific XAPIS HTTP response status code when a resource is already made so no further action is taken (usually for POST)
export const ALREADY_CREATED_CODE = 208;

export const NOOP = () => undefined;
export const LAST_PROJECT_KEY_COOKIE = 'glgoLastProjectKey';

export const ONELINKJS_USER_TYPE = 'onelinkjs';
export const GLGO_PROJECT_TYPE = 'GLGO';
export const GLWEB_PROJECT_TYPE = 'GLWEB';
export const OLJS_PROJECT_TYPE = 'OLJS';

export default GRC;
