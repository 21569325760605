import { Button, Group, Modal } from '@mantine/core';
import * as React from 'react';

type Props = {
  opened: boolean;
  setOpened: (bool: boolean) => void;
  deleteThisSku: string;
  doDeleteSku: () => Promise<void>;
};
const DeleteModal = ({
  opened,
  setOpened,
  deleteThisSku,
  doDeleteSku,
}: Props) => (
  <Modal opened={opened} onClose={() => setOpened(false)}>
    <Group>
      Are you sure that you want to delete {deleteThisSku} ?
      <Button onClick={doDeleteSku}>YES, DELETE IT.</Button>
      <Button onClick={() => setOpened(false)}>Cancel</Button>
    </Group>
  </Modal>
);

export default DeleteModal;
