import React from 'react';
import { List, Modal } from '@mantine/core';
import { Address } from 'store';

type Props = {
  opened: boolean;
  close: () => void;
  data: OrderContent;
};

export type OrderContent = object;

export const OrderModal = ({ opened, close, data = {} }: Props) => {
  const checkValue = (key: string, val: OrderContent) => {
    if (typeof val === 'boolean') return `${val}`;
    if (['subtotal', 'tax', 'total'].includes(key)) return `$${val}`;
    return val;
  };

  const itemArr = Object.entries(data);

  const orderContent = itemArr.map((item: [string, OrderContent]) => {
    const [key, value] = item;
    if (key === 'bill_to_address') {
      const { city, country, line1, postalCode } = value as Address;
      const addressTitles = ['city', 'country', 'line1', 'postalCode'];
      const addressValues = [city, country, line1, postalCode];
      return (
        <List.Item key={key}>
          {key}:
          <List key="addresses">
            {addressValues.map((addressValue, i) => (
              <List.Item key={addressTitles[i]}>
                <>
                  {addressTitles[i]}: {addressValue}
                </>
              </List.Item>
            ))}
          </List>
        </List.Item>
      );
    }

    if (
      ['shipping', 'other', 'user_key', 'order_items', 'payment_info'].includes(
        key
      )
    )
      return <div key={key} />;

    return (
      <List.Item key={key}>
        <>
          {key}: {checkValue(key, value)}
        </>
      </List.Item>
    );
  });

  return (
    <Modal opened={opened} onClose={close} title="More Info" centered size="md">
      <List>{orderContent}</List>
    </Modal>
  );
};

export default OrderModal;
