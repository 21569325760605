import React from 'react';
import logo from './logo.svg';

const ErrorView = () => (
  <>
    <div>
      <img src={logo} className="App-logo" alt="logo" />
    </div>
    <div>
      404 -- Not Found. Sorry the link you tried to visit does not exist.
    </div>
  </>
);

export default ErrorView;
