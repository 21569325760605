import * as React from 'react';
import './App.css';
import { useAuth } from 'react-oidc-context';
import {
  // AppShell,
  NavigationHeader,
  PayProjectSelector,
  SideNavigation,
} from 'ui';
import {
  DebugStore,
  StateStore,
  useDebugStore,
  UserStoreType,
  useStateStore,
  useUserStore,
} from 'store';
import { useDisclosure } from '@mantine/hooks';
import { useTPAuth } from 'hooks';
import logoLight from './logo-light.svg';
import { authorizedRoutes } from './routes';
import navigationLinks from './navLinks';
import packageJson from '../package.json';
import cheet from 'cheet.js';
import { AppShell } from '@mantine/core';
import { Outlet, Route, Routes } from 'react-router-dom';

const App = () => {
  const auth = useAuth();
  const { setRedirectUrl } = useStateStore((state: StateStore) => state);
  const {
    user: { user_key },
  } = useUserStore((state: UserStoreType) => state);
  const { debug, setDebug } = useDebugStore((state: DebugStore) => state);

  const [opened, { toggle, close }] = useDisclosure(false);

  const logo = logoLight;

  useTPAuth(auth);

  /* c8 ignore next 3 */
  (cheet as unknown as (str: string, cb: () => void) => void)(
    'ctrl d b g',
    () => {
      setDebug(!debug);
    }
  );

  /* c8 ignore next 4 */
  let actualLinks = [...navigationLinks];
  if (!debug) {
    actualLinks = actualLinks.filter((l) => l.label !== 'Debug');
  }

  /* c8 ignore next 23 */
  if (auth.isAuthenticated && user_key) {
    return (
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: 300,
          breakpoint: 'sm',
          collapsed: { mobile: !opened },
        }}
        padding="md"
      >
        <NavigationHeader
          logo={logo}
          navLinks={navigationLinks}
          opened={opened}
          toggle={toggle}
          close={close}
          version={`Build ${packageJson.version} built on ${document.documentElement.dataset.buildTimestampLocale}`}
        >
          <PayProjectSelector close={close} />
        </NavigationHeader>

        <SideNavigation auth={auth} navLinks={actualLinks} />

        <AppShell.Main p={0} pt={60}>
          <Routes>
            {authorizedRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.element />}
              />
            ))}
          </Routes>
          <Outlet />
        </AppShell.Main>
      </AppShell>
    );
  }

  const isProtectedLink = navigationLinks.find(
    (link) => link.path === document.location.pathname
  );
  /* c8 ignore next 9 */
  if (!auth.isAuthenticated && !auth.isLoading && isProtectedLink) {
    setRedirectUrl(isProtectedLink.path);
    auth.signinRedirect().catch((error) => {
      console.error('ERROR: ', error.toString());
    });
  }

  if (auth.isLoading) return <div>Loading...</div>;

  if (auth.error) {
    console.error('auth.error: ', auth.error);
    auth.signinRedirect().catch((error) => {
      console.error('ERROR: ', error.toString());
    });
    return <div>Oops... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated && !auth.isLoading) {
    // return <AppShell routes={unauthorizedRoutes} />;
    return <div />;
  }
  return <div />;
};

export default App;
