import * as React from 'react';
import { useState } from 'react';
import {
  Anchor,
  Burger,
  Container,
  Group,
  AppShell,
  Image,
  Paper,
  Transition,
} from '@mantine/core';
import { MainLink } from './MainLink';
import { NavListInterface } from '../../../types';
import classes from './NavigationHeader.module.css';

type NavigationHeaderProps = {
  logo: string;
  navLinks: NavListInterface[];
  opened: boolean;
  toggle: VoidFunction;
  close: () => void;
  children?: React.ReactNode;
  version?: string;
};

export const NavigationHeader = ({
  logo,
  navLinks,
  opened,
  toggle,
  close,
  children,
  version,
}: NavigationHeaderProps) => {
  const [active, setActive] = useState(0);

  const links = navLinks.map((link, index) => (
    <MainLink
      {...link}
      index={index}
      key={link.path}
      active={active === index}
      onClick={(menuIndex: number) => {
        setActive(menuIndex);
        close();
      }}
    />
  ));

  const getNavigationLogo = (width: number) => (
    <Anchor href="/">
      <Image
        title={version}
        my="auto"
        height="auto"
        src={logo}
        alt="logo"
        styles={{ root: { width } }}
      />
    </Anchor>
  );

  return (
    <AppShell.Header mah={60} px="1rem" className="header" bg="white">
      <Container
        className={classes.headerContainer}
        display={{ base: 'none', sm: 'flex' }}
        h="100%"
        px="1rem"
      >
        {getNavigationLogo(200)}
        {children}
      </Container>
      <Container
        display={{ base: 'flex', sm: 'none' }}
        className={classes.mobileHeader}
      >
        {getNavigationLogo(150)}
        <Group gap={5} className={classes.links}>
          {links}
        </Group>
        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
        />
        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {children}
              {links}
            </Paper>
          )}
        </Transition>
      </Container>
    </AppShell.Header>
  );
};
export default NavigationHeader;
