import * as React from 'react';
import { AppShell } from '@mantine/core';
import { AuthContextProps } from 'react-oidc-context';
import classes from './SideNavigation.module.css';
import { Logout } from '../../../Logout';
import { NavListInterface } from '../../../types';
import { MainLink } from './MainLink';

type SideNavigationProps = {
  auth: AuthContextProps;
  navLinks: NavListInterface[];
  children?: React.ReactNode;
};

export const SideNavigation = ({
  auth,
  navLinks,
  children,
}: SideNavigationProps) => {
  const [active, setActive] = React.useState(0);

  const links = navLinks.map(
    (link, index): JSX.Element => (
      <MainLink
        {...link}
        index={index}
        key={link.path}
        active={active === index}
        onClick={(linkIndex: number) => {
          setActive(linkIndex);
        }}
      />
    )
  );

  return (
    <AppShell.Navbar className={classes.navbar} p="xs">
      {children !== undefined && children}
      {links}
      <Logout auth={auth} />
    </AppShell.Navbar>
  );
};

export default SideNavigation;
