import * as React from 'react';
import { useForm } from '@mantine/form';
import { Button, Group, TextInput } from '@mantine/core';
import { PayProjectResponse } from 'store';

const defaultInitialValues = {
  pay_project_name: '',
  pay_key: '',
  is_active: true,
  pa_client_id: '',
};

const PayProjectForm = (props: {
  submit: (data: PayProjectResponse) => Promise<void>;
  initialValues?: PayProjectResponse;
  editMode?: boolean;
}) => {
  const {
    submit,
    editMode = false,
    initialValues = defaultInitialValues,
  } = props;
  const form = useForm<PayProjectResponse>({
    initialValues,
    validate: {
      pay_project_name: (value: string) =>
        value.length > 2
          ? null
          : 'Pay Project Name must be at least 3 characters',
    },
    validateInputOnChange: ['pay_project_name'],
    validateInputOnBlur: true,
  });
  const formSubmit = async () => {
    const { hasErrors } = form.validate();
    if (!hasErrors) {
      const { values: data } = form;
      await submit(data);
    }
  };

  return (
    <form>
      <div style={{ maxWidth: 750, margin: 'auto' }}>
        {editMode ? (
          <TextInput
            readOnly
            mt="md"
            label="Pay Key"
            placeholder="Pay Key"
            {...form.getInputProps('pay_key')}
          />
        ) : null}
        <TextInput
          mt="md"
          label="Pay Project Name"
          placeholder="Project Name"
          {...form.getInputProps('pay_project_name')}
        />
        <TextInput
          mt="md"
          label="Project A Client ID"
          placeholder="Project A Client ID"
          {...form.getInputProps('pa_client_id')}
        />
        <Group justify="center" mt="xl">
          <Button variant="outline" onClick={() => formSubmit()}>
            {editMode ? 'Update' : 'Add'}
          </Button>
        </Group>
      </div>
    </form>
  );
};

export default PayProjectForm;
