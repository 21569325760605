import { useContext } from 'react';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';
import { Box, Button, Flex, Grid, Text } from '@mantine/core';
import { useEditorColors } from '../../theme/useEditorColors';
import { useUnsavedCount } from '../../store/SegmentsState';

import { ActiveFilters } from './ActiveFilters';
import { Message } from './Message';

type DashboardProps = {
  onSaveSegments: () => void;
  segmentsCount: { total: number; filtered: number };
  SortByButtonsAndMenu: React.ReactElement;
  SearchBarAndFilterMenu: React.ReactElement;
};

const EditorDashboard = ({
  segmentsCount,
  SearchBarAndFilterMenu,
  SortByButtonsAndMenu,
  onSaveSegments,
}: DashboardProps) => {
  const colors = useEditorColors().dashboard;
  const unsavedCount = useUnsavedCount();
  const { viewOnly } = useContext(SegmentEditorContext).settings;

  const Count = () => {
    const { total, filtered } = segmentsCount;
    const formatNumber = new Intl.NumberFormat('en-US');
    return (
      <Flex>
        <Text fz={14} fw={600} c={colors.count}>{`${formatNumber.format(
          filtered
        )} `}</Text>
        <Text fz={14} fw={600} c="blue.8" style={{ whiteSpace: 'pre' }}>{` of ${formatNumber.format(
          total
        )} Segments`}</Text>
      </Flex>
    );
  };

  return (
    <Flex w="100%" align="center" pt={8} pb={4} px={35}>
      <Grid w="100%" m={0}>
        <Grid.Col
          p={0}
          span={{base: 12, sm: 6, md: 4}}
        >
          <Box w="100%">
            {SearchBarAndFilterMenu}
            <Flex
              w="100%"
              p={'5px 15px'}
              align="center"
              justify="space-between"
              columnGap={10}
            >
              <Count />
              {/* Not implemented yet */}
              {/* <Button
                className={classes.advancedSearch}
                variant="outline"
                leftIcon={<FaPlus size={12} />}
              >
                Advanced Search
              </Button> */}
            </Flex>
          </Box>
        </Grid.Col>
        <Grid.Col
          span={{base: 0, md: 4, lg: 5}}
          py={0}
          px={{ base: 15, md: 30 }}
          display={{ base: 'none', md: 'block' }}
        >
          <ActiveFilters />
        </Grid.Col>
        <Grid.Col
          span={{base: 12, sm: 6, md: 4, lg: 3}}
          px={0}
          pt={0}
          pb={{ base: 5, sm: 0 }}
        >
          <Flex w="100%" justify="flex-end">
            <Flex w="fit-content" direction="column" rowGap={5}>
              <Flex w="100%" gap={10} justify="flex-end" align="center">
                {SortByButtonsAndMenu}
                <Flex>
                  {viewOnly ? (
                    <Button disabled>View Only</Button>
                  ) : (
                    <Button
                      miw={{ base: 'auto', md: 100 }}
                      onClick={onSaveSegments}
                      disabled={!unsavedCount}
                    >
                      {unsavedCount ? `Save (${unsavedCount})` : 'Save'}
                    </Button>
                  )}
                </Flex>
              </Flex>
              <Message />
            </Flex>
          </Flex>
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default EditorDashboard;
