import * as React from 'react';
import { Link } from 'react-router-dom';
import { Group, Text, ThemeIcon, UnstyledButton } from '@mantine/core';
import { NavLinkInterface } from '../../../types';
import classes from './MainLink.module.css';

export const MainLink = ({
  icon,
  label,
  path,
  active,
  index,
  onClick,
}: NavLinkInterface) => {
  return (
    <Link to={path} style={{ textDecoration: 'none' }}>
      <UnstyledButton
        onClick={() => onClick(index)}
        className={classes.navLink}
      >
        <Group>
          <ThemeIcon variant="light">{icon}</ThemeIcon>
          <Text size={active ? 'lg' : 'sm'} fw={active ? 'bold' : 'normal'}>
            {label}
          </Text>
        </Group>
      </UnstyledButton>
    </Link>
  );
};

export default MainLink;
