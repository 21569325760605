import React, { useEffect, useState } from 'react';
import { useRevalidator } from 'react-router-dom';
import { Editor } from '@monaco-editor/react';
import { Flex, Loader, Button, Tooltip, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Xapis } from 'store';
import { AlertIcon } from 'images';
import {
  hexToAscii,
  isInContextWindow,
  isSuccessStatus,
  isYYTranslation,
} from 'helpers';
import { saveCustomCss } from './customCssActions';
import { CSSHistoryModal } from './CSSHistoryModal';
import classes from './LanguageCSSEditor.module.css';
import { LangSelectorPreview } from './LangSelectorPreview';

const CancelButton = Button.withProps({
  w: { base: 'unset', xs: '120px' },
  variant: 'outline',
});
const SaveButton = Button.withProps({
  w: { base: 'unset', xs: '120px' },
  variant: 'filled',
});

type Props = {
  target?: TranslationKey;
};

const isInContext = isInContextWindow();
const { updateCustomCss, gotElementSelector } = window.editorAPI || {};

const previewCss = (css: string) => {
  if (!updateCustomCss) return;
  updateCustomCss(css);
};
// NOTE: This component is used in several places in the codebase.
// It receives a target prop and let the user edit the custom_css
// for that target (which can be the YY target).
// It should NOT relay on the URL to fetch data.
export const LanguageCSSEditor = ({ target }: Props) => {
  const revalidator = useRevalidator();
  const isYYtarget = isYYTranslation(target);
  const { custom_css = '' } = target || {};
  const currentCssValue = hexToAscii(custom_css);
  const [hasWarnings, setHasWarnings] = useState<boolean>(false);
  const [cssValue, setCssValue] = useState(currentCssValue || '');
  const [revisions, setRevisions] = useState<RevisionItem[]>([]);
  const [newSelector, setNewSelector] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const handleEditorChange = (value: string | undefined) => {
    if (!(typeof value === 'string')) return;
    setCssValue(value);
    if (value === currentCssValue) previewCss(value);
  };
  const isUnsaved = cssValue !== currentCssValue;

  const handleSave = () => {
    if (!target) {
      notifications.show({
        message: 'Oops, there was a problem with saving your edits.',
      });
      return;
    }
    setLoading(true);
    saveCustomCss({ target, cssValue })
      .then(({ status }) => {
        if (isSuccessStatus(status))
          notifications.show({ message: 'Successfully saved your edits!' });
        else {
          notifications.show({
            message: 'Oops, there was a problem with saving your edits.',
          });
        }
      })
      .catch(() => {
        notifications.show({
          message: 'Oops, there was a problem with saving your edits.',
        });
      })
      .finally(() => {
        revalidator.revalidate();
        fetchRevisions(target.translation_key);
        previewCss(cssValue);
        setLoading(false);
      });
  };

  const addNewCssSelector = () => {
    setCssValue((prev) => `${prev}\n${newSelector} {\n\n}`);
    setNewSelector('');
  };

  const fetchRevisions = (tKey: string) => {
    Xapis.ConfigHistory.get(tKey, 'custom_css').then(
      ({ status, revisions }) => {
        if (!isSuccessStatus(status)) {
          notifications.show({
            type: 'error',
            message: 'There was a problem with fetching the revisions.',
          });
        }
        setRevisions(revisions);
      }
    );
  };

  useEffect(() => {
    // This is needed for cases where the custom_css is updated
    // outside of this component (e.g. from the SelectorDefaults component)
    setCssValue(currentCssValue);
  }, [custom_css]);

  useEffect(() => {
    if (!target) return;
    fetchRevisions(target.translation_key);
    gotElementSelector &&
      gotElementSelector((selector) => setNewSelector(selector));
  }, [target?.translation_key]);

  return (
    <>
      <Flex direction="column" gap={20}>
        {newSelector && (
          <Flex align={'center'} gap={8}>
            <Button
              className={classes.icon}
              variant="transparent"
              onClick={addNewCssSelector}
            >
              Add new CSS
            </Button>
            <Text className={classes.newSelector}>{newSelector}</Text>
            <Button
              className={classes.icon}
              variant="transparent"
              onClick={() => setNewSelector('')}
            >
              X
            </Button>
          </Flex>
        )}
        <Editor
          height="50vh"
          language="css"
          defaultValue={currentCssValue}
          loading={<Loader />}
          onChange={handleEditorChange}
          onValidate={(markers) => setHasWarnings(markers?.length > 0)}
          value={cssValue}
        />
        <Flex w="100%" justify="space-between" gap={20}>
          <Flex gap={5}>
            <CSSHistoryModal
              revisions={revisions}
              unsavedCss={isUnsaved ? cssValue : null}
            />
            {isYYtarget && <LangSelectorPreview css={cssValue} key="custom" />}
          </Flex>
          <Flex gap={15}>
            <CancelButton
              disabled={cssValue === currentCssValue}
              className={classes.cancelButton}
              onClick={() => {
                setCssValue(currentCssValue);
                previewCss(currentCssValue);
              }}
            >
              Cancel
            </CancelButton>
            {isInContext && (
              <SaveButton
                disabled={cssValue === currentCssValue}
                className={classes.saveButton}
                onClick={() => {
                  previewCss(cssValue);
                }}
              >
                Preview
              </SaveButton>
            )}
            <Tooltip
              label="Check warnings before saving"
              disabled={!hasWarnings}
            >
              <SaveButton
                disabled={!isUnsaved || loading}
                className={classes.saveButton}
                onClick={handleSave}
                leftSection={hasWarnings ? <AlertIcon /> : null}
              >
                {loading ? <Loader size="sm" /> : 'Save'}
              </SaveButton>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
